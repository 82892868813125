import { Backdrop, Box, CircularProgress } from "@mui/material";
import React from "react";
const logoLobos = process.env.PUBLIC_URL + "/img/lobos.png";

export default function WrapperLoading() {
	return (
		<>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
					flexDirection: "column",
				}}
				open={true}
				/* onClick={handleClose} */
			>
				<Box
					sx={{
						background: "white",
						borderRadius: "8px",
						padding: "1rem",
						marginBottom: "2rem",
					}}
					className={"backImage"}
				>
					<img src={logoLobos} alt="Logo" />
				</Box>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
}
