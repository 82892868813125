import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import {
	Button,
	CssBaseline,
	TextField,
	FormControlLabel,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { Link as LinkRouter, Navigate, useNavigate } from "react-router-dom";
import Http from "../../../lib/Http";
import { validateEmail } from "../../../utils/validateEmail";
import { useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import BackImage from "./BackImage/BackImage";
import WrapperLoading from "../../UI/organisms/WrapperLoading";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";

const logoLobos = process.env.PUBLIC_URL + "/img/lobos.png";

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			<a color="inherit" href="https://lobosmillonarios.com/">
				LobosMillonarios
			</a>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

export default function Login({ currentUser }) {
	const { updateUser } = useContext(AuthContext);
	const [showPassword, setShowPassword] = React.useState(false);
	const [loading, setLoading] = useState(false);
	const matches = useMediaQuery("(max-width:600px)");
	const [showOTP, setShowOTP] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const handleSubmit = async (event) => {
		setLoading(true);
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		let email = data.get("email");
		let password = data.get("password");
		let otp = data.get("otp");

		if (password.length < 5) {
			enqueueSnackbar(
				"El campo contraseña no puede estar vacio o la contraseña es muy corta ",
				{
					variant: "error",
					autoHideDuration: 3000,
				}
			);

			setLoading(false);
			return false;
		}

		if (validateEmail(email)) {
			let response = await Http.instance.loginWithEmail(email, password, otp);

			if (response.status === 404) {
				enqueueSnackbar("Email o contraseña incorrectas", {
					variant: "error",
					autoHideDuration: 3000,
				});

				setLoading(false);
				return false;
			}

			if (response.status === "OTP") {
				setShowOTP(true);
				setLoading(false);
				return false;
			}

			if (response.status === "OTPInvalid") {
				enqueueSnackbar(response?.message, {
					variant: "error",
					autoHideDuration: 3000,
				});

				setLoading(false);
				return false;
			}

			if (response.data.data.data.token) {
				const { token, user } = response.data.data.data;
				let last_login = Date.now();
				setLoading(false);
				updateUser(token, user, last_login);
			} else {
				setLoading(false);
				enqueueSnackbar(response?.message, {
					variant: "error",
					autoHideDuration: 3000,
				});
			}
		} else {
			setLoading(false);
			enqueueSnackbar("Email incorrecto", {
				variant: "error",
				autoHideDuration: 3000,
			});
		}
	};

	return (
		<>
			{loading && <WrapperLoading />}
			{/* Redireccion si ya esta autenticado */}
			{currentUser && <Navigate to="/" replace />}
			<Grid container component="main" sx={{ height: "100vh" }}>
				<CssBaseline />
				<Grid
					item
					xs={false}
					sm={4}
					md={8}
					sx={{
						backgroundImage:
							"url(https://source.unsplash.com/collection/4912110/forex-trader )",
						backgroundRepeat: "no-repeat",
						backgroundColor: (t) =>
							t.palette.mode === "light"
								? t.palette.grey[50]
								: t.palette.grey[900],
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
				>
					<BackImage />
				</Grid>
				<Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						{matches ? (
							<Box>
								<img
									src={logoLobos}
									alt="Logo Lobos millonarios"
									style={{ maxWidth: "120px" }}
								/>
							</Box>
						) : (
							<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
								<LockOutlinedIcon />
							</Avatar>
						)}
						<Typography component="h1" variant="h5">
							Ingresar
						</Typography>
						<Box
							component="form"
							noValidate
							onSubmit={handleSubmit}
							sx={{ mt: 1 }}
						>
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="Correo electronico"
								name="email"
								autoComplete="email"
								autoFocus
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Contraseña"
								type={showPassword ? "text" : "password"}
								id="password"
								autoComplete="current-password"
							/>
							<FormControlLabel
								control={
									<Checkbox
										value="showPassword"
										color="primary"
										onChange={(e) => {
											setShowPassword(e.target.checked);
										}}
									/>
								}
								label="Ver contraseña"
							/>

							{showOTP && (
								<TextField
									margin="normal"
									required
									fullWidth
									name="otp"
									label="Codido de Google auth"
									id="otp"
								/>
							)}

							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								Ingresar
							</Button>
							<Grid container>
								<Grid item xs>
									<LinkRouter to="/restore-password">
										<Typography
											variant="body2"
											color="text.secondary"
											align="left"
										>
											Olvidaste la <br /> contraseña?
										</Typography>
									</LinkRouter>
								</Grid>
								<Grid item>
									<LinkRouter to="/signin" variant="body2">
										<Typography
											variant="body2"
											color="text.secondary"
											align="center"
										>
											No tienes cuenta? Registrate
										</Typography>
									</LinkRouter>
								</Grid>
							</Grid>
							<br />
							<Copyright sx={{ mt: 5 }} />
						</Box>
					</Box>
				</Grid>
			</Grid>
		</>
	);
}
