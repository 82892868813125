import { Box, Typography, Grid } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
const binance = "/img/logo_binance.png";
const coinbase = "/img/logo_coinbase.svg";
const bitso = "/img/logo_bitso.png";
const metamask = "/img/logo_metamask.png";

const WalletQR = ({ currentRow }) => {
  // console.log(currentRow);
  const imageWallet = () => {
    let img = "";
    switch (currentRow?.name_wallet) {
      case "1":
        img = binance;
        break;
      case "2":
        img = coinbase;
        break;
      case "3":
        img = bitso;
        break;
      case "4":
        img = metamask;
        break;
      default:
        img = "otro";
        break;
    }
    if (img === "otro") {
      return (
        <AccountBalanceWalletIcon
          sx={{ mr: 1, fontSize: { xs: 24, md: 40 } }}
        />
      );
    } else {
      return (
        <img
          src={img}
          alt="QR Wallet"
          style={{
            maxHeight: 40,
            minHeight: 24,
            marginRight: 10,
          }}
        />
      );
    }
  };

  const nameWallet = () => {
    switch (currentRow?.name_wallet) {
      case "1":
        return "Binance";
      case "2":
        return "Coinbase";
      case "3":
        return "Bitso";
      case "4":
        return "Metamask";
      default:
        return currentRow?.name_wallet;
    }
  };
  return (
    <>
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: currentRow?.url_qr?.length > 2 ? "flex" : "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            minHeight: { xs: "50px", md: "270px" },
            width: "100%",
            height: "100%",
            borderRadius: "12px",
            border: "2px solid #040404",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {imageWallet()}
          <Typography variant={"h5"}>{nameWallet()}</Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          display: currentRow?.url_qr?.length > 2 ? "flex" : "none",
        }}
      >
        <Box
          sx={{
            width: "100%",
            bgcolor: "#f3f3f3",
            display: "flex",
            justifyContent: "center",
            padding: 1,
            marginBottom: 1,
            borderRadius: 2,
          }}
        >
          <img
            src={currentRow?.url_qr}
            alt="QR Wallet"
            style={{ maxHeight: 300, minHeight: 270 }}
          />
        </Box>
      </Grid>
    </>
  );
};
export default WalletQR;
