export function validateDataInvestor(data) {
	if (data) {
		//validacion de datos de textos primarios (text)
		let arrayError = [];
		if (
			data.name.trim().length > 2 &&
			//data.tipo_de_documento.trim().length > 1 &&
			data.tipo_documento.trim() !== "" &&
			data.pais.trim().length > 3 &&
			data.documento.toString().length > 5 &&
			data.celular.toString().length > 5 &&
			data.metodo_de_retiro !== 0 &&
			data.ciudad.trim().length > 3 &&
			data.provincia.trim().length > 3
		) {
			//validacion -> tener al menos un tipo de retiro completo
			/* cripto */
			if (data.metodo_de_retiro === "2" && data.wallet.length > 5) {
				return true;
			} else {
				arrayError.push("Wallet invalida");
			}
			/* transferencia bancaria */
			if (
				data.metodo_de_retiro === "1" &&
				data.banco.length > 3 &&
				(data.clabe.length > 3 || data.swift.length > 3) &&
				data.cuenta_bancaria.toString().length > 5
			) {
				return true;
			} else {
				arrayError.push("Datos bancarios incorrectos");
			}

			//otro tipo de metodo de retiro
			if (data.metodo_de_retiro === "3" && data.otro_metodo.length > 3) {
				return true;
			} else {
				arrayError.push("Wallet invalida");
			}

			return false;
		}

		return false;
	} else {
		return false;
	}
}
