import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Dashboard from "../UI/organisms/Dashboard";

export default function PrivateOutlet({ user }) {
	return user ? (
		<Dashboard user={user}>
			<Outlet />
		</Dashboard>
	) : (
		<Navigate to="/login" />
	);
}
