import React, { Suspense, useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import AuthContext from "./context/AuthContext";
import {
	cookies,
	PARAMS_TIME_LAST_LOGIN,
	TOKEN_KEY,
	KEY_CURRENT_USER,
} from "./lib/Http";
/* initial components */
import PrivateOutlet from "./components/functionals/PrivateOutlet";
import WrapperLoading from "./components/UI/organisms/WrapperLoading";
import Login from "./components/pages/auth/Login";
import Policies from "./components/pages/generalPage/Policies";
import Pruebas from "./components/pages/pruebas/Pruebas";
import Files from "./components/pages/user/Files";
import InMaintenance from "./components/pages/generalPage/InMaintenance";

import AllInvestments from "./components/pages/administration/AllInvestments";
import GeneralInformation from "./components/pages/administration/GeneralInformation";
import GroupWithdrawalls from "./components/pages/administration/GroupWithdrawalls";
import Security from "./components/pages/user/Security";
//Boton flotante
import Fab from "@mui/material/Fab";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";

/* General routes */
const SignIn = React.lazy(() =>
	import(/* webpackChunkName: "LZ SignIn" */ "./components/pages/auth/SignIn")
);
const FinishRegistration = React.lazy(() =>
	import(
		/* webpackChunkName: "LZ FinishRegistration" */ "./components/pages/auth/FinishRegistration"
	)
);
const NewPassword = React.lazy(() =>
	import(
		/* webpackChunkName: "LZ NewPassword" */ "./components/pages/auth/NewPassword"
	)
);
const RestorePassword = React.lazy(() =>
	import(
		/* webpackChunkName: "LZ RestorePassword" */ "./components/pages/auth/RestorePassword"
	)
);

const WithdrawalsRequests = React.lazy(() =>
	import(
		/* webpackChunkName: "LZ WithdrawAllMoney" */ "./components/pages/administration/WithdrawalsRequests"
	)
);

const WithdrawAllMoney = React.lazy(() =>
	import(
		/* webpackChunkName: "LZ WithdrawAllMoney" */ "./components/pages/user/WithdrawAllMoney"
	)
);

/* Investor routes */
const Profile = React.lazy(() =>
	import(/* webpackChunkName: "LZ Profile" */ "./components/pages/user/Profile")
);
const Investments = React.lazy(() =>
	import(
		/* webpackChunkName: "LZ Investments" */ "./components/pages/user/Investments"
	)
);

const Preguntas = React.lazy(() =>
	import(
		/* webpackChunkName: "LZ Preguntas" */ "./components/pages/user/Preguntas"
	)
);

const Founds = React.lazy(() =>
	import(/* webpackChunkName: "LZ Founds" */ "./components/pages/user/Funds")
);
/* mis retiros */
const MyWithdrawals = React.lazy(() =>
	import(
		/* webpackChunkName: "LZ Founds" */ "./components/pages/user/MyWithdrawals"
	)
);

/* Super Admin Routes */
//import FundingRequests from "./components/pages/administration/FundingRequests";
const FundingRequests = React.lazy(() =>
	import(
		/* FundingRequests: "LZ Founds" */ "./components/pages/administration/FundingRequests"
	)
);
const SearchInversor = React.lazy(() =>
	import(
		/* webpackChunkName: "LZ SearchInversor" */ "./components/pages/administration/SearchInversor"
	)
);

const ViewAsInvestor = React.lazy(() =>
	import(
		/* webpackChunkName: "LZ viewAsInvestor" */ "./components/pages/administration/ViewAsInvestor"
	)
);

const Dashboard = React.lazy(() =>
	import(
		/* webpackChunkName: "LZ viewAsInvestor" */ "./components/pages/administration/Dashboard"
	)
);

const GeneralInfo = React.lazy(() =>
	import(
		/* webpackChunkName: "LZ viewAsInvestor" */ "./components/pages/administration/GeneralInfo"
	)
);

function App() {
	const { currentUser, updateUser, dataInvestor, loading, inMaintenance } =
		useContext(AuthContext);
	const [sanitizeDataInvestor, setSanitizeDataInvestor] = useState(false);
	const [sanitizeCurrentUser, setSanitizeCurrentUser] = useState(false);

	const mostarAlerta = () => {
		let responseUser = window.confirm("¿Deseas hablar con alguien del equipo?");

		if (responseUser) {
			window.location.href =
				"https://api.whatsapp.com/send/?phone=523318599052&text&type=phone_number&app_absent=0";
		}
	};

	useEffect(() => {
		if (currentUser) {
			setSanitizeCurrentUser(currentUser);
		}
	}, [currentUser]);

	useEffect(() => {
		if (dataInvestor) {
			setSanitizeDataInvestor(dataInvestor);
		}
	}, [dataInvestor]);

	useEffect(() => {
		let token = cookies.get(TOKEN_KEY);
		let user = cookies.get(KEY_CURRENT_USER);
		let lastLogin = cookies.get(PARAMS_TIME_LAST_LOGIN);

		if (
			token !== "undefined" &&
			user !== "undefined" &&
			lastLogin !== "undefined" &&
			token
		) {
			updateUser(token, user, lastLogin);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let currentURL = window.location.href;
	let isInDevEnv = currentURL.includes("localhost");

	return (
		<>
			{inMaintenance && !isInDevEnv ? (
				<div>
					<h1>{!loading && <InMaintenance />}</h1>
				</div>
			) : (
				<Routes>
					{/* 	<Route exact path="/login" element={<Login />} /> */}
					{loading ? (
						<Route path="*" element={<WrapperLoading />} />
					) : (
						<>
							<Route
								exact
								path="/login"
								element={<Login currentUser={currentUser} />}
							/>
							<Route
								path="/signin"
								element={
									<Suspense fallback={<WrapperLoading />}>
										<SignIn />
									</Suspense>
								}
							/>
							<Route
								path="/reset-password"
								element={
									<Suspense fallback={<WrapperLoading />}>
										<FinishRegistration />
									</Suspense>
								}
							/>

							<Route
								path="/new-password"
								element={
									<Suspense fallback={<WrapperLoading />}>
										<NewPassword />
									</Suspense>
								}
							/>

							<Route
								path="/restore-password"
								element={
									<Suspense fallback={<WrapperLoading />}>
										<RestorePassword />
									</Suspense>
								}
							/>

							<Route
								user={currentUser}
								element={<PrivateOutlet user={currentUser} />}
							>
								<>
									<Route
										path=""
										element={
											<Suspense fallback={<WrapperLoading />}>
												{sanitizeCurrentUser.role === "5" ||
												(sanitizeCurrentUser.role === "1" &&
													sanitizeCurrentUser !== undefined) ? (
													<FundingRequests />
												) : (
													<Investments dataInvestor={sanitizeDataInvestor} />
												)}
											</Suspense>
										}
									/>

									<Route
										path="login"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<Profile currentUser={currentUser} />
											</Suspense>
										}
									/>
									<Route
										path="perfil"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<Profile currentUser={currentUser} />
											</Suspense>
										}
									/>
									<Route
										path="preguntas"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<Preguntas />
											</Suspense>
										}
									/>
									<Route
										path="fondear"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<Founds />
											</Suspense>
										}
									/>

									<Route
										path="retiros"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<MyWithdrawals />
											</Suspense>
										}
									/>

									<Route
										path="politicas"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<Policies />
											</Suspense>
										}
									/>

									<Route
										path="seguridad"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<Security />
											</Suspense>
										}
									/>

									<Route
										path="archivos"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<Files dataInvestor={sanitizeDataInvestor} />
											</Suspense>
										}
									/>

									<Route
										path="retirar-todo"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<WithdrawAllMoney />
											</Suspense>
										}
									/>

									{/* admin */}
									<Route
										path="general"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<GeneralInfo />
											</Suspense>
										}
									/>

									<Route
										path="estadisticas"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<Dashboard />
											</Suspense>
										}
									/>

									<Route
										path="admin-retiros"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<WithdrawalsRequests />
											</Suspense>
										}
									/>
									<Route
										path="grupo-retiros"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<GroupWithdrawalls />
											</Suspense>
										}
									/>
									<Route
										path="admin-fondeos"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<h1>Admin</h1>
											</Suspense>
										}
									/>

									<Route
										path="pruebas"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<Pruebas />
											</Suspense>
										}
									/>

									<Route
										path="admin-inversiones"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<AllInvestments />
											</Suspense>
										}
									/>
									<Route
										path="consultar-inversor"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<SearchInversor />
											</Suspense>
										}
									/>
									<Route
										path="fondo"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<GeneralInformation />
											</Suspense>
										}
									/>

									<Route
										path="ver-como"
										element={
											<Suspense fallback={<WrapperLoading />}>
												<ViewAsInvestor dataInvestor={sanitizeDataInvestor} />
											</Suspense>
										}
									/>
								</>
							</Route>
						</>
					)}

					{/* <Route path="*" element={<Navigate to="/" replace />} /> */}
				</Routes>
			)}
			<Fab
				sx={{
					position: "fixed",
					bottom: 65,
					right: 20,
					zIndex: 500,
					height: 45,
					width: 45,
					borderRadius: 50,
					backgroundColor: "#25D366",
					color: "white",
				}}
				variant="extended"
				size="small"
				aria-label="add"
				onClick={mostarAlerta}
			>
				<WhatsAppIcon />
			</Fab>
		</>
	);
}

export default App;
