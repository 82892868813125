import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

//MUI
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "universal-cookie";

import AuthContext from "../../../../context/AuthContext";
//Estilos de Modal
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 900,
  minWidth: 350,
  minHeight: { xs: 200, md: 300 },
  maxHeight: "95%",
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "12px",
  overflowX: "hidden",
  justifyContent: "center",
  display: "flex",
};

const ModalAboutDoc = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const { dataInvestor, _getDataCurrentUser } = useContext(AuthContext);

  const nameCookie = "openFirstTime-fj";
  const cookie = new Cookies();

  const modalClose = () => {
    cookie?.set(nameCookie, "true", { path: "/", maxAge: 86400 });
    setOpen(!open);
  };

  // Obtener los datos del dataInvestor
  // useEffect(() => {
  //   _getDataCurrentUser();
  // }, []);

  // Validar Cookie
  useEffect(() => {
    let myCookie = cookie?.get(nameCookie);

    if (!myCookie && dataInvestor) {
      if (
        !dataInvestor.bn_uno_nombre ||
        !dataInvestor.bn_uno_celular ||
        !dataInvestor.bn_uno_email ||
        !dataInvestor.bn_dos_nombre ||
        !dataInvestor.bn_dos_celular ||
        !dataInvestor.bn_dos_email
      ) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [dataInvestor]);

  return (
    <Modal open={open}>
      <Box sx={modalStyle}>
        <Grid container>
          {/* Encabezado */}
          <Grid
            container
            item
            xs={12}
            sx={{
              height: "60px",
              mx: 2,
              p: 1,
              borderBottom: "1px solid #ebebeb",
            }}
          >
            <Grid
              item
              xs={11}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ display: "flex" }}
            >
              <Typography variant="h6" sx={{ color: "#000" }}>
                {/* Completa tus datos Personales */}
                <strong>¡Actualización de Datos!</strong>
              </Typography>
            </Grid>
            <Grid
              item
              xs={1}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ display: "flex" }}
            >
              <IconButton
                color="secondary"
                onClick={() => {
                  modalClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container item xs={12} sx={{ p: "1.7rem 1.5rem" }} spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ color: "#000" }}>
                Hemos actualizado los tipos de documentos permitidos, bancos y
                la lista de países. A partir de ahora, solo se aceptarán{" "}
                <strong>INE</strong> o <strong>Pasaporte</strong> como
                documentos válidos. Para asegurarte de que tus datos estén
                actualizados correctamente, por favor, revisa la sección "Perfil
                - Información del Inversor".
                <br />
                <Button
                  onClick={() => navigate("/preguntas")}
                  variant="text"
                  sx={{ textAlign: "left", color: "#259ed2" }}
                >
                  <Typography variant="body2">
                    ¿Y si no tengo INE o Pasaporte?
                  </Typography>
                </Button>
                <br />
                <br />
                <strong>Recuerda agregar a tus beneficiarios</strong>, aquellas
                personas que reclamarían tus inversiones en caso de
                fallecimiento.
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ display: "flex" }}
            >
              <img
                src={`/img/completar_perfil.png`}
                alt={"Completar Datos"}
                width="100%"
                // height="170"
              />
            </Grid>

            <Grid item xs={12} sx={{ mt: 1 }}>
              <Button
                fullWidth
                variant="contained"
                startIcon={<CloseIcon />}
                sx={{ borderRadius: "10px" }}
                onClick={() => modalClose()}
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalAboutDoc;
