import React from "react";
import moment from "moment";
import {
  Grid,
  Paper,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WalletQR from "../WalletQR";

export default function WithDrawalsDataGroup({ currentRow, setOpenModal }) {
  // Copiar al portapapeles
  const copyToClipboard = (text = "") => {
    navigator.clipboard.writeText(text);
  };
  return (
    <>
      <Paper
        sx={{
          width: "90%",
          alignSelf: "center",
          height: "auto",
          maxHeight: "80vh",
          padding: 2,
          marginTop: 1,
          overflowX: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              size={"small"}
              label="Id Inversor"
              variant="outlined"
              value={currentRow?.inversor}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              size={"small"}
              label="Email Inversor"
              variant="outlined"
              value={currentRow?.email}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              size={"small"}
              label="Fecha de solicitud"
              variant="outlined"
              value={moment(currentRow?.created_on).format("YYYY/MM/DD HH:MM")}
              fullWidth
              disabled
            />
          </Grid>
          {/*fin Primera fila */}

          {/* Datos retiro */}
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              size={"small"}
              label="Nombre Inversor"
              variant="outlined"
              value={currentRow?.nombre}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              size={"small"}
              label="Moneda"
              variant="outlined"
              value={currentRow?.moneda === "1" ? "MXN" : "USD"}
              fullWidth
              disabled
            />
          </Grid>

          {/* fin monto y moneda */}

          <Grid item xs={12} sm={6} md={3}>
            {currentRow?.metodo_de_retiro === "1" && (
              <TextField
                size={"small"}
                label="Metodo de retiro"
                variant="outlined"
                value={"Transferencia Bancaria"}
                fullWidth
                disabled
              />
            )}
            {currentRow?.metodo_de_retiro === "2" && (
              <TextField
                size={"small"}
                label="Metodo de retiro"
                variant="outlined"
                value={"Cripto USDT - TRC 20"}
                fullWidth
                disabled
              />
            )}

            {currentRow?.metodo_de_retiro === "3" && (
              <TextField
                size={"small"}
                label="Metodo de retiro"
                variant="outlined"
                value={"Cripto USDT - ERC 20"}
                fullWidth
                disabled
              />
            )}
          </Grid>
          {/* Determinando protocolo */}
          {currentRow?.metodo_de_retiro != "1" && (
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                size={"small"}
                label="Metodo de retiro"
                variant="outlined"
                value={
                  currentRow?.metodo_de_retiro === "2"
                    ? "USDT- TRC 20"
                    : "ERC -20 - USDT"
                }
                fullWidth
                disabled
              />
            </Grid>
          )}
          {/* Condicional si fue tranferencia o cripto */}
          {currentRow?.metodo_de_retiro === "1" ? (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size={"small"}
                  label="Banco"
                  variant="outlined"
                  value={currentRow?.banco}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size={"small"}
                  label="Clabe"
                  variant="outlined"
                  value={currentRow?.clabe}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => copyToClipboard(currentRow?.clabe)}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size={"small"}
                  label="Cuenta Bancaria"
                  variant="outlined"
                  value={currentRow?.cuenta_bancaria}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            copyToClipboard(currentRow?.cuenta_bancaria)
                          }
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          ) : (
            <>
              {currentRow?.metodo_de_retiro === "2" ? (
                <>
                  <Grid item xs={12} sm={6} md={9}>
                    <TextField
                      size={"small"}
                      label="Wallet de destino TRC-20"
                      variant="outlined"
                      value={currentRow?.wallet}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                copyToClipboard(currentRow?.wallet)
                              }
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6} md={9}>
                    <TextField
                      size={"small"}
                      label="otra Wallet ERC-20"
                      variant="outlined"
                      value={currentRow?.otro_metodo}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                copyToClipboard(currentRow?.otro_metodo)
                              }
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          {/* fibnd fila 2 */}

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              size={"small"}
              label="Pais"
              variant="outlined"
              value={currentRow?.pais}
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              size={"small"}
              label="Ciudad"
              variant="outlined"
              value={currentRow?.ciudad}
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              size={"small"}
              label="Provincia - Estado"
              variant="outlined"
              value={currentRow?.provincia}
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              size={"small"}
              label="Estado"
              variant="outlined"
              value={
                currentRow?.status === "draft" ? "Pendiente" : "Confirmado"
              }
              fullWidth
              color="primary"
            />
          </Grid>

          {currentRow.metodo_de_retiro !== "1" && (
            <WalletQR currentRow={currentRow} />
          )}

          <Grid item xs={12}>
            <Button
              color="secondary"
              fullWidth
              variant="contained"
              onClick={() => setOpenModal(false)}
            >
              Cerrar
            </Button>
          </Grid>
          {/* Final fila 3 */}
        </Grid>
      </Paper>
    </>
  );
}
