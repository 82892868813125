export function generateTemplate(arrayMessage, dataInversor) {
	console.log(dataInversor);

	let messageLines = (arrayMessage) => {
		let ArrayLines = [];

		arrayMessage.map((item) => {
			let line = `<p>${item}</p> <br />`;
			ArrayLines.push(line);
		});

		return ArrayLines.join(" ");
	};

	let template = `
        <h1><span style="color:#FFFFFF">Notificación de cambio de datos</span></h1>
        <br />
        <h3><span style="color:#FFFFFF">El usuario:  ${
					dataInversor?.nombre
				} -  email ( ${dataInversor?.email})</span></h3>
        <br />
        <h3><span style="font-family:lora,georgia,times new roman,serif"><strong>ha cambiado sus datos de retiro</strong> <br></h3>
        <br />
        <h3><span style="font-family:lora,georgia,times new roman,serif">Lo datos cambiados son:<br></h3>
        <br>
        ${messageLines(arrayMessage)}
    &nbsp;
    
    `;

	return template;
}
