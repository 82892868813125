const metodo_de_retiro = {
  0: "Seleccione una opción de retiro",
  1: "Transferencia Bancaria",
  2: "Cripto - USDT TRC20",
  3: "USDT - ERC 20",
};

const tipo_transferencia = {
  0: "Seleccione una opción de retiro",
  1: "[Mexico] Transferencia en Mexico",
  2: "Transferencia Internacional",
};

const typeWallet = {
  0: "Seleccione una Wallet",
  1: "Binance",
  2: "Coinbase",
  3: "Bitso",
  4: "Metamask",
  5: "Otra",
};

const valueNull = "Dato vacío";

export function validationUpdatedFields(data, _dataApi) {
  let messages = [];

  Object.keys(data).forEach((key) => {
    if (
      data[key] !== _dataApi[key] &&
      !(data[key] === "" && _dataApi[key] === null) &&
      !(data[key] === null && _dataApi[key] === "")
    ) {
      //validar cambio de metodo de retiro
      if (key === "metodo_de_retiro") {
        let message = `El valor de ${key} ha cambiado de ${
          metodo_de_retiro[_dataApi[key]] || valueNull
        } a ${metodo_de_retiro[data[key] || valueNull]}`;
        messages.push(message);
      }

      //tipo_transferencia
      if (key === "tipo_transferencia") {
        let message = `El valor de ${key} ha cambiado de ${
          tipo_transferencia[_dataApi[key]] || valueNull
        } a ${tipo_transferencia[data[key] || valueNull]}`;
        messages.push(message);
      }

      if (key === "wallet") {
        let message = `El valor de ${key} para el protocolo TRC20 ha cambiado de ${
          _dataApi[key] || valueNull
        } a ${data[key] || valueNull}`;
        messages.push(message);
      }

      if (key === "type_wallet") {
        let message = `El valor de tipo de wallet ha cambiado de ${
          typeWallet[_dataApi[key]] || valueNull
        } a ${typeWallet[data[key]] || valueNull}`;
        messages.push(message);
      }

      //Datos adicionales
      if (
        key === "banco" ||
        key === "clabe" ||
        key === "swift" ||
        key === "cuenta_bancaria" ||
        key === "cripto"
      ) {
        let message = `El valor de ${key} ha cambiado de ${
          _dataApi[key] || valueNull
        } a ${data[key] || valueNull}`;
        messages.push(message);
      }

      if (key === "other_wallet") {
        let message = `El valor de "Nombre de la Billetera" ha cambiado de ${
          _dataApi[key] || valueNull
        } a ${data[key] || valueNull}`;
        messages.push(message);
      }

      if (key === "url_qr") {
        let message = `El valor de la url del QR ha cambiado de ${
          _dataApi[key] || valueNull
        } a ${data[key] || valueNull}`;
        messages.push(message);
      }

      // if (key === "otro_metodo") {
      //   let message = `El valor de la Wallet ERC-20 ha cambiado de ${
      //     _dataApi[key] || valueNull
      //   } a ${data[key] || valueNull}`;
      //   messages.push(message);
      // }
    }
  });

  if (!data || !_dataApi) {
    messages = [];
  }

  console.log(messages);
  return messages;
}
