import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Box, Button } from "@mui/material";
import Http from "../../../lib/Http";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { separator } from "../../../lib/formatNumber";
import { useSnackbar } from "notistack";

export default function AllInvestments() {
	const [allDraftInvestment15, setAllDraftInvestment15] = useState([]);
	const [allDraftInvestment27, setAllDraftInvestment27] = useState([]);
	const [allInvestment, setAllInvestment] = useState([]);
	const { enqueueSnackbar } = useSnackbar();

	const reqGetAllInvestmentsWithParams = async (periodo, fecha) => {
		let response = await Http.instance.getAllDrafInvestments(periodo, fecha);
		if ((response.status = 200)) {
			switch (periodo) {
				case "1":
					setAllDraftInvestment15(response.data);
					break;
				case "2":
					setAllDraftInvestment27(response.data);
					break;

				default:
					break;
			}
		} else {
			enqueueSnackbar("Ocurrio un error en la lectura de inveriones", {
				variant: "error",
				autoHideDuration: 3000,
			});
		}
	};

	const getAllInvestment = async () => {
		let response = await Http.instance.getAllInvestmentsSuperAdmin();
		if ((response.status = 200)) {
			setAllInvestment(response.data);
			//console.log(response.data);
		} else {
			enqueueSnackbar("Ocurrio un error en la lectura de todas lasinveriones", {
				variant: "error",
				autoHideDuration: 3000,
			});
		}
	};

	useEffect(() => {
		let now = Date.now();
		let dateMoment = moment(now).date(15).format("YYYY-MM-DD");

		reqGetAllInvestmentsWithParams("1", dateMoment);
		reqGetAllInvestmentsWithParams("2", dateMoment);
		getAllInvestment();
		return () => reqGetAllInvestmentsWithParams();
	}, []);

	const handelReturns = async (row) => {
		console.log(row);
		let response = await Http.instance.giveReturns(row);
		console.log(response);
		if (response.status === 200) {
			console.log(response.message);
		} else {
			enqueueSnackbar(response?.message, {
				variant: "error",
				autoHideDuration: 3000,
			});
		}
		setTimeout(() => {
			window.location.reload();
		}, 3000);
	};

	const columns15 = [
		{ field: "id", headerName: "ID", width: 70, sortable: false },

		{
			field: "created_on",
			headerName: "Fecha creación",
			width: 150,
			sortable: true,
			renderCell: (params) => {
				let date = params.row.created_on;
				return <>{moment(date).format("YYYY/MM/DD HH:mm")}</>;
			},
		},
		{
			field: "fecha_de_alta",
			headerName: "Fecha de alta",
			width: 130,
			sortable: true,
			renderCell: (params) => {
				let fecha = params.row.fecha_de_alta;
				return <>{fecha}</>;
			},
		},
		{
			field: "ultimos_rendimientos",
			headerName: "Ultimos rendimientos",
			width: 150,
			sortable: true,
			renderCell: (params) => {
				let fecha = params.row.ultimos_rendimientos;
				return <>{fecha}</>;
			},
		},
		{
			field: "periodo",
			headerName: "Periodo",
			width: 130,
			sortable: true,
			renderCell: (params) => {
				let periodo = params.row.periodo;
				return <>{periodo === "1" ? "Retiro los 15" : "Retiro los 27"}</>;
			},
		},
		{
			field: "inversor",
			headerName: "Inversor",
			width: 250,
			sortable: true,
			renderCell: (params) => {
				let monto = params.row.inversor.email;
				return <>{separator(monto)}</>;
			},
		},
		{
			field: "nombre",
			headerName: "Nombre",
			width: 250,
			sortable: true,
			renderCell: (params) => {
				let name = params.row.inversor.nombre;
				return <>{name}</>;
			},
		},
		{
			field: "moneda",
			headerName: "Moneda",
			width: 100,
			sortable: true,
			renderCell: (params) => {
				let moneda = params.row.moneda;
				return (
					<>
						{moneda === "1" && "MXN"} {moneda === "2" && "USD"}
					</>
				);
			},
		},

		{
			field: "monto",
			headerName: "Monto",
			width: 110,
			sortable: true,
			renderCell: (params) => {
				let monto = params.row.monto;
				return <>{separator(monto)}</>;
			},
		},
		{
			field: "tipo",
			headerName: "Tipo ",
			width: 110,
			sortable: true,
			renderCell: (params) => {
				let tipo = params.row.tipo;
				return <>{!tipo ? "Fondeo" : "Rendimiento"}</>;
			},
		},
		{
			field: "Acciones",
			headerName: "Acciones ",
			width: 250,
			sortable: true,
			renderCell: (params) => {
				let now = Date.now();
				let day = moment(now).format("D"); //hoy
				return (
					<>
						<Button
							variant="outlined"
							onClick={() => {
								handelReturns(params.row);
							}}
							disabled={
								day === "15" || day === "16" || day === "17" ? false : true
							}
						>
							Agregar Redimientos
						</Button>
					</>
				);
			},
		},
	];

	const columns27 = [
		{ field: "id", headerName: "ID", width: 70, sortable: false },

		{
			field: "created_on",
			headerName: "Fecha creación",
			width: 150,
			sortable: true,
			renderCell: (params) => {
				let date = params.row.created_on;
				return <>{moment(date).format("YYYY/MM/DD HH:mm")}</>;
			},
		},
		{
			field: "fecha_de_alta",
			headerName: "Fecha de alta",
			width: 130,
			sortable: true,
			renderCell: (params) => {
				let fecha = params.row.fecha_de_alta;
				return <>{fecha}</>;
			},
		},
		{
			field: "ultimos_rendimientos",
			headerName: "Ultimos rendimientos",
			width: 150,
			sortable: true,
			renderCell: (params) => {
				let fecha = params.row.ultimos_rendimientos;
				return <>{fecha}</>;
			},
		},
		{
			field: "periodo",
			headerName: "Periodo",
			width: 130,
			sortable: true,
			renderCell: (params) => {
				let periodo = params.row.periodo;
				return <>{periodo === "1" ? "Retiro los 15" : "Retiro los 27"}</>;
			},
		},
		{
			field: "inversor",
			headerName: "Inversor",
			width: 250,
			sortable: true,
			renderCell: (params) => {
				let monto = params.row.inversor.email;
				return <>{separator(monto)}</>;
			},
		},
		{
			field: "nombre",
			headerName: "Nombre",
			width: 250,
			sortable: true,
			renderCell: (params) => {
				let name = params.row.inversor.nombre;
				return <>{name}</>;
			},
		},
		{
			field: "moneda",
			headerName: "Moneda",
			width: 100,
			sortable: true,
			renderCell: (params) => {
				let moneda = params.row.moneda;
				return (
					<>
						{moneda === "1" && "MXN"} {moneda === "2" && "USD"}
					</>
				);
			},
		},

		{
			field: "monto",
			headerName: "Monto",
			width: 110,
			sortable: true,
			renderCell: (params) => {
				let monto = params.row.monto;
				return <>{separator(monto)}</>;
			},
		},
		{
			field: "tipo",
			headerName: "Tipo ",
			width: 110,
			sortable: true,
			renderCell: (params) => {
				let tipo = params.row.tipo;
				return <>{!tipo ? "Fondeo" : "Rendimiento"}</>;
			},
		},
		{
			field: "Acciones",
			headerName: "Acciones ",
			width: 250,
			sortable: true,
			renderCell: (params) => {
				let now = Date.now();
				let day = moment(now).format("D"); //hoy
				return (
					<>
						<Button
							variant="outlined"
							onClick={() => {
								handelReturns(params.row);
							}}
							disabled={
								day === "27" || day === "28" || day === "29" ? false : true
							}
						>
							Agregar Redimientos
						</Button>
					</>
				);
			},
		},
	];

	const columnsAllReturns = [
		{ field: "id", headerName: "ID", width: 70, sortable: false },

		{
			field: "created_on",
			headerName: "Fecha creación",
			width: 150,
			sortable: true,
			renderCell: (params) => {
				let date = params.row.created_on;
				return <>{moment(date).format("YYYY/MM/DD HH:mm")}</>;
			},
		},
		{
			field: "fecha_de_alta",
			headerName: "Fecha de alta",
			width: 130,
			sortable: true,
			renderCell: (params) => {
				let fecha = params.row.fecha_de_alta;
				return <>{fecha}</>;
			},
		},
		{
			field: "ultimos_rendimientos",
			headerName: "Ultimos rendimientos",
			width: 150,
			sortable: true,
			renderCell: (params) => {
				let fecha = params.row.ultimos_rendimientos;
				return <>{fecha}</>;
			},
		},
		{
			field: "periodo",
			headerName: "Periodo",
			width: 130,
			sortable: true,
			renderCell: (params) => {
				let periodo = params.row.periodo;
				return <>{periodo === "1" ? "Retiro los 15" : "Retiro los 27"}</>;
			},
		},
		{
			field: "inversor",
			headerName: "Inversor",
			width: 250,
			sortable: true,
			renderCell: (params) => {
				let email = params.row.inversor.email;

				return <>{email}</>;
			},
		},
		{
			field: "nombre",
			headerName: "Nombre",
			width: 250,
			sortable: true,
			renderCell: (params) => {
				let name = params.row.inversor.nombre;
				return <>{name}</>;
			},
		},
		{
			field: "moneda",
			headerName: "Moneda",
			width: 100,
			sortable: true,
			renderCell: (params) => {
				let moneda = params.row.moneda;
				return (
					<>
						{moneda === "1" && "MXN"} {moneda === "2" && "USD"}
					</>
				);
			},
		},

		{
			field: "monto",
			headerName: "Monto",
			width: 110,
			sortable: true,
			renderCell: (params) => {
				let monto = params.row.monto;
				return <>{separator(monto)}</>;
			},
		},
		{
			field: "tipo",
			headerName: "Tipo ",
			width: 110,
			sortable: true,
			renderCell: (params) => {
				let tipo = params.row.tipo;
				return <>{!tipo ? "Fondeo" : "Rendimiento"}</>;
			},
		},
		{
			field: "estatus",
			headerName: "Estado de fondos ",
			width: 140,
			sortable: true,
			renderCell: (params) => {
				let solicitado = params.row.solicitado;
				return <>{solicitado ? "Solicitado" : "Congelado"}</>;
			},
		},
	];

	const [sortModel, setSortModel] = React.useState([
		{ field: "id", sort: "desc" },
	]);

	return (
		<>
			<Grid container>
				{/* {isLoading && <WrapperLoading />} */}

				<>
					<Paper sx={{ flexGrow: 1 }}>
						<Typography textAlign={"center"} color="primary" variant="h5" m={1}>
							Administrar Inversiones Retiro los 15 {"("}
							{allDraftInvestment15.length}
							{")"}
						</Typography>
					</Paper>

					<Box
						component={Paper}
						sx={{
							minHeight: "500px",
							width: "100%",
							marginTop: "10px",
							marginBottom: "10px",
						}}
					>
						<DataGrid
							columns={columns15}
							rows={allDraftInvestment15}
							sortModel={sortModel}
							onSortModelChange={(model) => setSortModel(model)}
						/>
					</Box>
				</>

				<>
					<Paper sx={{ flexGrow: 1 }}>
						<Typography textAlign={"center"} color="primary" variant="h5" m={1}>
							Administrar Inversiones Retiro los 27
						</Typography>
					</Paper>

					<Box
						component={Paper}
						sx={{
							minHeight: "500px",
							width: "100%",
							marginTop: "10px",
							marginBottom: "10px",
						}}
					>
						<DataGrid
							columns={columns27}
							rows={allDraftInvestment27}
							sortModel={sortModel}
							onSortModelChange={(model) => setSortModel(model)}
						/>
					</Box>
				</>

				<>
					<Paper sx={{ flexGrow: 1 }}>
						<Typography textAlign={"center"} color="primary" variant="h5" m={1}>
							Todas las inversiones a la fecha
						</Typography>
					</Paper>

					<Box
						component={Paper}
						sx={{
							minHeight: "800px",
							width: "100%",
							marginTop: "10px",
							marginBottom: "10px",
						}}
					>
						<DataGrid
							columns={columnsAllReturns}
							rows={allInvestment}
							sortModel={sortModel}
							onSortModelChange={(model) => setSortModel(model)}
						/>
					</Box>
				</>
			</Grid>
		</>
	);
}
