import Axios from "axios";
import moment from "moment";
import Cookies from "universal-cookie";
import { generateTemplate } from "../utils/generateTemplate";
import { validateDataInvestor } from "../utils/validateDataInvestor";
import { validationUpdatedFields } from "../utils/validationUpdatedFields";
export const enableSendingEmails = true;
export const localConnect = false;
export const verComo = window.location.hostname === "localhost";
export const adminEmail = "fergodinez@lobosmillonarios.com";
//export const adminEmail = "cristian@xiclica.com";

//export const urlProduction = "https://lobosmillonarios.com/portal";
//export const urlProduction = "https://portal.lobosmillonarios.com";
export const subFolder = "fondo";

//opction  local , test , production
export const enviroment = "production";

export const urlProduction = "https://portal.lobosmillonarios.com";
export const urlTest = "https://pruebasfijo.lobosmillonarios.com";
export const urlDev = "http://localhost:8888/loboslocal";

// localConnect ? "loboslocal" : "portal"
export var URL_BASE;

export var PROJECT;

switch (enviroment) {
	// Ambiente de Produccion de Pruebas
	case "test":
		URL_BASE = urlTest;
		PROJECT = "pruebasfijo";
		break;

	// Ambiente de Produccion de Original
	case "production":
		URL_BASE = urlProduction;
		PROJECT = "portal";
		break;

	// Ambiente de Produccion de Local
	case "local":
		URL_BASE = urlDev;
		PROJECT = "loboslocal";
		break;

	default:
		break;
}

//export const urlDev = "http://localhost:8888/lobos-directus";

// export const PROJECT = localConnect ? "lobos-directus" : "portal";
export const TOKEN_KEY = "directus-lobos-session";
export const KEY_CURRENT_USER = "currentUser";
export const cookies = new Cookies();
export const LAST_LOGIN = "last-login";
export const PARAMS_TIME_LAST_LOGIN = "7200000";

export const _currentUser = cookies.get(KEY_CURRENT_USER) || [{}];

export const isDev = true;
const devLocal = false;

export default class Http {
	static instance = new Http();

	axiosInterceptor = () => {
		Axios.interceptors.request.use(function (config) {
			const token = cookies.get(TOKEN_KEY);

			if (token) {
				config.headers.Authorization = `bearer ${token}`;
			}
			return config;
		});
		/* detectar un 401 y cerrar sesion */
		const UNAUTHORIZED = 401;
		Axios.interceptors.response.use(
			(response) => response,
			(error) => {
				const { status } = error?.response;
				if (status === UNAUTHORIZED) {
					console.log("token invalido -sesion cerrada");
					this.logOut();
					window.location.reload();
				}
				return Promise.reject(error);
			}
		);
	};

	/* En mantenimineto */

	inMaintenance = async () => {
		if (!devLocal) {
			const uri = `${URL_BASE}/public/${PROJECT}/items/mantenimiento`;

			try {
				let response = await Axios.get(uri);

				return {
					status: 200,
					data: response.data.data,
					message: "Consulta si el portal esta operativo",
				};
			} catch (error) {
				console.log(error);

				return {
					status: 409,
					data: error,
					message:
						"Hubo un error intentando conultar el estado operativo del portal",
				};
			}
		}
	};

	manageError(data) {
		console.log(data.error);
		let error_message = data.error.response.data.error.message;
		console.error(error_message);

		this.registerLog(
			data.functionName,
			data.message,
			data.error,
			_currentUser,
			data.other,
			error_message
		);
		console.log(data.message);

		return {
			status: 409,
			data: data.error,
			message: data.message,
		};
	}

	registerLog = async (
		trigger,
		descripcion,
		error,
		current_user,
		others,
		error_message
	) => {
		/* trigger la funcion que lo ejecuto */
		/* descripcion, que se espera que hiciera */
		/*  error que devuelve*/
		/* current user : usuario actual para ver permisos  */
		/* other : valiables propias de la llamada */

		const uri = `${URL_BASE}/public/${PROJECT}/items/logs`;

		try {
			let res = await Axios({
				method: "post",
				url: uri,
				data: {
					trigger: trigger,
					descripcion,
					error,
					current_user,
					others: others,
					error_message: error_message,
				},
			});
			console.log("log registrado ");
			return res;
		} catch (error) {
			console.error(error.response.data.error.message);
			console.log("No se pudo guardar el log");
			console.log(error);
		}
	};

	// Auth Sections
	loginWithEmail = async (email, password, otp) => {
		const uri = `${URL_BASE}/public/${PROJECT}/auth/authenticate`;

		try {
			let res = await Axios({
				method: "post",
				url: uri,
				data: {
					email: email,
					password: password,
					otp: otp,
					mode: isDev ? "jwt" : "cookie",
				},
			});

			return { status: 200, data: res, message: "Login success" };
		} catch (error) {
			let OTPmessage = "User missing 2FA OTP";
			let OTPmessageInvalid = "Invalid user OTP";
			let responseMessageError = error.response.data.error.message;

			console.error(responseMessageError);
			if (responseMessageError === OTPmessage) {
				return {
					status: "OTP",
					data: null,
					message: "Agregue codigo de Google Auth",
				};
			}

			if (OTPmessageInvalid === responseMessageError) {
				return {
					status: "OTPInvalid",
					data: null,
					message: "codigo de Google Auth Invalido",
				};
			}

			return {
				status: 404,
				data: null,
				message: "Email o contraseña incorrecta",
			};
		}
	}; //fin login

	// Refreh token
	refreshToken = async (token) => {
		const uri = `${URL_BASE}/public/${PROJECT}/auth/refresh`;

		try {
			let res = await Axios({
				method: "post",
				url: uri,
				data: {
					token: token,
				},
			});
			if (res.data.data.token) {
				cookies.set(TOKEN_KEY, res.data.data.token, { path: "/" });
				let now = Date.now();
				cookies.set(LAST_LOGIN, now, { path: "/" });
			}

			return { status: 200, data: res, message: "Login success" };
		} catch (error) {
			let other = {};
			console.error(error.response.data.error.message);
			this.registerLog(
				"refreshToken",
				"Actualizar el token en local ",
				error,
				_currentUser,
				other
			);
			console.log(error);
			console.error(error.response.data.error.message);
			cookies.remove(TOKEN_KEY, { path: "/" });
			cookies.remove(KEY_CURRENT_USER, { path: "/" });
			cookies.remove(LAST_LOGIN, { path: "/" });
			return {
				status: 404,
				data: null,
				message: "Refrech Token error, LogOut",
			};
		}
	}; // fin refresh Token

	retrieveCurrentUser = async () => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/users/me`;

		try {
			let res = await Axios.get(uri);
			console.log(res);
		} catch (error) {
			let other = {};
			let error_message = error.response.data.error.message;
			console.error(error_message);
			this.registerLog(
				"retrieveCurrentUser",
				"Consultar usuario actual",
				error,
				_currentUser,
				other,
				error_message
			);
		}
	};

	logOut = async () => {
		cookies.remove(TOKEN_KEY, { path: "/" });
		cookies.remove(KEY_CURRENT_USER, { path: "/" });
		cookies.remove(LAST_LOGIN, { path: "/" });
		window.location.reload("/");
		return { message: "LogOut sucesses" };
	};

	restorePasword = async (email) => {
		const resetUrl = `${URL_BASE}/public/${PROJECT}/password-reset`;
		const uri = `${URL_BASE}/public/${PROJECT}/auth/password/request`;
		try {
			let res = await Axios({
				method: "post",
				url: uri,
				data: {
					email: email,
					reset_url: resetUrl,
				},
			});

			return { status: 200, data: res, message: "Login success" };
		} catch (error) {
			let other = { email };
			let error_message = error.response.data.error.message;
			console.error(error_message);
			this.registerLog(
				"restorePasword",
				" Recuperar contraseña - Modelo basico",
				error,
				_currentUser,
				other,
				error_message
			);

			console.log(error);
			console.error(error.response.data.error.message);
			return {
				status: 404,
				data: null,
				message: "Email no existe dentro de los usuarios del sitio",
			};
		}
	};

	createUser = async (email, first_name, last_name) => {
		const uri = `${URL_BASE}/public/${PROJECT}/custom/auth/register/inversor`;
		const sanitizeEmail = email.toLowerCase();

		try {
			let res = await Axios({
				method: "post",
				url: uri,
				data: {
					email: sanitizeEmail,
					first_name,
					last_name,
				},
			});

			if (res.status === 200) {
				return {
					status: 200,
					data: res,
					message:
						"Cuenta creada, te enviamos un email para que termines el proceso de registro",
				};
			}
			/* ; */
		} catch (error) {
			let other = { email, first_name, last_name };
			let error_message = error.response.data.error.message;
			console.error(error_message);
			this.registerLog(
				"createUser",
				"Endpoint personalizado -> crear un usuario con rol inversor ",
				error,
				_currentUser,
				other,
				error_message
			);
			console.error(error.response.data.error.message);
			console.log(error.message);
			console.log(error.status);
			return {
				status: 500,
				data: null,
				message:
					"Ocurrio un error, posiblemente ya hay una cuenta con ese email, por favor vaya a la opcion Restablecer contraseña",
			};
		}
	};

	resetValidateEmail = async (email) => {
		const url = "reset-password";
		const resetUrl = `https://fondo.lobosmillonarios.com/${url}`;
		const uri = `${URL_BASE}/public/${PROJECT}/auth/password/request`;

		try {
			let res = await Axios({
				method: "post",
				url: uri,
				data: {
					email: email,
					reset_url: resetUrl,
				},
			});

			return {
				status: 200,
				data: res,
				message:
					"Cuenta creada, te enviamos un email para que termines el proceso de registro",
			};
		} catch (error) {
			console.log(error);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			let other = { email };
			this.registerLog(
				"resetValidateEmail",
				"Enviar email con un link para que el usuario pueda confirmar el email -> proceso de registro",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 404,
				data: null,
				message: "Email no existe dentro de los usuarios del sitio",
			};
		}
	};

	establishNewPassword = async (password, token) => {
		const uri = `${URL_BASE}/public/${PROJECT}/auth/password/reset`;
		try {
			let res = await Axios({
				method: "post",
				url: uri,
				data: {
					token: token,
					password: password,
				},
			});

			return {
				status: 200,
				data: res,
				message: "Contraseña agregada a tu cuenta",
			};
		} catch (error) {
			console.log(error);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			let other = {};
			this.registerLog(
				"establishNewPassword",
				"Se recibio un token y se intento restablecer contraseña al email asociado enviando contraseña",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 404,
				data: null,
				message: "Error estableciendo nueva contraseña",
			};
		}
	};

	resetPassword = async (email) => {
		const url = "new-password";
		const resetUrl = `https://${subFolder}.lobosmillonarios.com/${url}`;

		console.log(resetUrl);
		const uri = `${URL_BASE}/public/${PROJECT}/auth/password/request`;

		try {
			let res = await Axios({
				method: "post",
				url: uri,
				data: {
					email: email,
					reset_url: resetUrl,
				},
			});

			return {
				status: 200,
				data: res,
				message: "Enviamos un email para restablecer tu contraseña",
			};
		} catch (error) {
			console.log(error);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			return {
				status: 404,
				data: null,
				message: "Email no existe dentro de los usuarios del sitio",
			};
		}
	};

	/*End auth */

	/* validate inversor */

	validateInversor = async () => {
		isDev && this.axiosInterceptor();
		const current_user = cookies.get(KEY_CURRENT_USER);
		const uri = `${URL_BASE}/public/${PROJECT}/items/inversores?filter[email]=${current_user.email}&fields=*.*`;
		//&fields=banco,celular,ciudad,clabe,codigo_bancario,contrato.*,created_on,criptomoneda,cuenta_bancaria,documento,email,id,indicativo_de_pais,inversor_id,metodo_de_retiro,modified_on,nombre,owner,pais,politicas,protocolo,provincia,status,swift,tipo_de_documento,tipo_documento,tipo_transferencia,wallet
		// const current_user = cookies.get(KEY_CURRENT_USER);

		try {
			const res = await Axios.get(uri);

			if (res.status === 200 && res.data.data.length > 0) {
				return {
					status: 200,
					data: res.data.data,
					message: "Inversor existe",
				};
			} else {
				return {
					status: 200,
					data: res.data.data,
					message: "Debes Completar unos datos.",
				};
			}
		} catch (error) {
			console.log("Ocurrio un error en la solicitud para validar al inversor");
			let error_message = error.response.data.error.message;
			console.error(error_message);
			let other = {};
			this.registerLog(
				"validateInversor",
				"Validar si el inversor existe y si ya completo todos sus datos",
				error,
				_currentUser,
				other,
				error_message
			);

			console.log(error);
			return false;
		}
	};

	// Subir Imagen y posterior actualizar inversor
	uploadQRWallet = async (
		data,
		method,
		id,
		creationDate,
		presentDate,
		daysToMilliseconds,
		file
	) => {
		this.axiosInterceptor();
		const url = `${URL_BASE}/public/${PROJECT}/files`;
		try {
			let response = await Axios.post(
				url,
				file,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				},
				false
			);

			if (response.status === 200) {
				let responseInversor = await this.saveInvestor(
					data,
					method,
					id,
					creationDate,
					presentDate,
					daysToMilliseconds,
					response?.data?.data?.data?.full_url
				);

				if (responseInversor && responseInversor.status !== 200) {
					return {
						status: 500,
						message:
							"Ocurrio un error, por favor comuniquese con soporte para validar ",
					};
				}
				return responseInversor;
			}
		} catch (error) {
			console.error(error?.response?.data?.error?.message);
			return {
				status: 500,
				error: error,
				message:
					"Ocurrio un error, por favor comunique se con soporte para validar ",
			};
		}
	};

	/* Guardar inversor y validar si todos los datos estan completos */
	saveInvestor = async (
		data,
		method,
		id,
		creationDate,
		presentDate,
		daysToMilliseconds,
		urlQR
	) => {
		//validar fecha de creacion del usuario

		isDev && this.axiosInterceptor();
		const status = validateDataInvestor(data) ? "published" : "draft";

		const uri_post = `${URL_BASE}/public/${PROJECT}/items/inversores`;
		const uri_patch = `${URL_BASE}/public/${PROJECT}/items/inversores/${id}`;

		const uri = method === "post" ? uri_post : uri_patch;
		const current_user = cookies.get(KEY_CURRENT_USER);
		const sanitizeCelular = parseInt(data.celular);
		const sanitizeEmail = data.email.toLowerCase();

		if (creationDate) {
			let differenceBetweenDates = presentDate - creationDate;
			//Si la cuenta fue creada hace mas de 5 dias enviar notificacion de
			//cambio de datos de pago
			if (differenceBetweenDates > daysToMilliseconds) {
				const getDataInversor = `${URL_BASE}/public/${PROJECT}/items/inversores/${id}`;
				let response = await Axios.get(getDataInversor);
				let _dataApi = response?.data?.data;

				let messages = validationUpdatedFields(data, _dataApi);
				//si el array de mensajes con datos de pago tiene almenos un cambio se envia notificacion

				if (messages.length > 0) {
					//enviar notificacion

					if (enableSendingEmails) {
						const sendNotificationUrl = `${URL_BASE}/public/${PROJECT}/custom/dataChangeNotification`;
						const template = generateTemplate(messages, _dataApi);
						let letResponseEmail = await Axios({
							method: "post",
							url: sendNotificationUrl,

							data: {
								email: adminEmail,
								nombre: _dataApi?.nombre,
								body: template,
								custom: {
									name: "Fernando",
								},
							},
						});
						if (letResponseEmail.status === 200) {
							alert(
								"Hemos notificado a nuestro equipo de tu cambio en los datos de retiro"
							);
							console.log("Email de notificación enviado");
						}
					}
				}

				// codigo para enviar notificacion // TODO crear codigo
			}
		}

		try {
			let res = await Axios({
				method: method,
				url: uri,
				data: {
					status: status,
					nombre: data.name,
					email: sanitizeEmail,
					tipo_de_documento: data.tipo_de_documento,
					tipo_documento: data.tipo_documento,
					documento: data.documento,
					pais: data.pais,
					inversor_id: current_user.id,
					celular: sanitizeCelular,
					indicativo_de_pais: data.indicativo_de_pais,
					metodo_de_retiro: data.metodo_de_retiro,
					politicas: data.politicas,
					banco: data.banco,
					clabe: data.clabe,
					swift: data.swift,
					cuenta_bancaria: data.cuenta_bancaria,
					criptomoneda: data.criptomoneda,
					//protocolo: data.protocolo,
					wallet: data.wallet,
					tipo_transferencia: data.tipo_transferencia,
					ciudad: data.ciudad,
					provincia: data.provincia,
					otro_metodo: data.otro_metodo,
					url_qr: urlQR || data?.url_qr || "",
					other_wallet: data?.other_wallet,
					type_wallet: data?.type_wallet,
					bn_uno_nombre: data?.bn_uno_nombre,
					bn_uno_celular: data?.bn_uno_celular,
					bn_uno_email: data?.bn_uno_email,
					bn_dos_nombre: data?.bn_dos_nombre,
					bn_dos_celular: data?.bn_dos_celular,
					bn_dos_email: data?.bn_dos_email,
				},
			});

			return {
				status: 200,
				data: res,
				message: "Hemos guardado tus datos exitosamente",
			};
		} catch (error) {
			let error_message = error.response.data.error.message;
			console.log(error_message);

			let other = { data, method, id };
			this.registerLog(
				"saveInvestor",
				"crear o actualizar datos de un inversor ",
				error,
				_currentUser,
				other,
				error_message
			);

			return {
				status: error.status,
				data: error,
				message:
					"Hubo un error intentando guardar o actualizar los datos del inversor",
			};
		}
	};

	/*  Subir imagenes de documento*/
	uploadDocument = async (file, type, id_inversor) => {
		isDev && this.axiosInterceptor();
		const url = `${URL_BASE}/public/${PROJECT}/files`;
		try {
			let response = await Axios.post(
				url,
				file,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				},
				false
			);

			if (response.status === 200) {
				console.log(response.data.data);
				this.updateDocumentInvestor(id_inversor, response.data.data.id, type);
				return true;
			}
		} catch (error) {
			console.log(error);
			return false;
		}
	};

	updateDocumentInvestor = async (id_inversor, file_id, type) => {
		isDev && this.axiosInterceptor();
		let fields = type === "front" ? "document_front" : "document_later";
		const url = `${URL_BASE}/public/${PROJECT}/items/inversores/${id_inversor}`;

		try {
			let response = await Axios({
				method: "patch",
				url: url,
				data: {
					[fields]: file_id,
				},
			});
			alert("Subida exitosa");
			window.location.reload();
			return response;
		} catch (error) {
			console.log(error);
		}
	};

	/* subir archivos*/
	uploadFile = async (f) => {
		isDev && this.axiosInterceptor();
		const url = `${URL_BASE}/public/${PROJECT}/files`;

		try {
			let res = await Axios.post(
				url,
				f,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				},
				false
			);
			return res;
		} catch (error) {
			console.log(error);
		}
	};

	/* subir archivos*/
	updateFile = async (f, id) => {
		isDev && this.axiosInterceptor();
		const url = `${URL_BASE}/public/${PROJECT}/files/${id}`;

		try {
			let res = await Axios.patch(
				url,
				{ data: f },
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				},
				true
			);
			return res;
		} catch (error) {
			console.log(error);
		}
	};

	/*uploadContract */
	uploadContract = async (file) => {
		isDev && this.axiosInterceptor();
		const url = `${URL_BASE}/public/${PROJECT}/files`;

		try {
			let response = await Axios.post(
				url,
				file,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				},
				false
			);

			return response;
		} catch (error) {
			let error_message = error.response.data.error.message;
			console.error(error_message);

			let other = {};
			this.registerLog(
				"newFound",
				"Subir contrato ligado a un fondeo ",
				error,
				_currentUser,
				other,
				error_message
			);
		}
	};

	/* Fondeos */
	newFound = async (data, current_user, selectedContract) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/fondeos`;
		const __wallet = data.metodo === "2" ? data.wallet : "";
		const __cuenta_bancaria = data.metodo === "1" ? data.cuenta_bancaria : "";
		const __banco = data.metodo === "1" ? data.banco : "";
		const __moneda = data.moneda.toString();

		try {
			//TODO actualizar cuando se gestione la subida de contratos
			// let responseUploadFile = await this.uploadContract(selectedContract);

			// let idContract = responseUploadFile?.data?.data?.id;

			/* if (responseUploadFile?.status === 200) { */
			//console.log("Contrato subido");

			let responseNewFound = await Axios({
				method: "post",
				url: uri,
				data: {
					nombre_inversor: data.nombre,
					email_inversor: current_user.email,
					monto: data.monto,
					pais: data.pais,
					moneda: __moneda,
					metodo_fondeo: data.metodo,
					wallet: __wallet,
					cuenta_bancaria: __cuenta_bancaria,
					banco: __banco,
					fecha: data.fecha,
					ciudad: data.ciudad,
					provincia: data.provincia,
					clabe: data.clabe,
					swift: data.swift,
					inversor: data.inversor,
					//contract: idContract,
				},
			});

			if (responseNewFound.status === 200) {
				return {
					status: 200,
					data: responseNewFound,
					message:
						"Hemos informado tu confirmacion de fondeo y enviado tu contrato",
				};
			} else {
				alert("Ocurrio un error subiendo el contrato");
			}
			/* } else {
				alert("No se ha podido subir el contrato");
				return false;
			} */
		} catch (error) {
			console.log(error);
			let error_message = error.response.data.error.message;
			console.error(error_message);

			let other = {
				data,
				current_user,
				__wallet,
				__cuenta_bancaria,
				__banco,
			};
			this.registerLog(
				"newFound",
				"crear un fondeo con los tipos de datos suministrados y validando el tipo de tranferencia que se hara ",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message: "Hubo un error intentando realizar el registro de fondeo",
			};
		}
	};

	addFoundingMessage = async (message, id) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/fondeos/${id}`;
		try {
			let response = await Axios({
				method: "patch",
				url: uri,
				data: {
					mensaje: message,
				},
			});

			if (response.status === 200) {
				return {
					status: 200,
					data: response,
					message: "Hemos agregado un mensaje de fondeo",
				};
			}
		} catch (error) {
			let error_message = error.response.data.error.message;
			console.error(error_message);

			let other = {
				message,
				id,
			};
			this.registerLog(
				"addFoundingMessage",
				"Error agregando mensaje de fondeo",
				error,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,

				message: error_message,
			};
		}
	};

	getAllFound = async () => {
		isDev && this.axiosInterceptor();
		const current_user = cookies.get(KEY_CURRENT_USER);
		const uri = `${URL_BASE}/public/${PROJECT}/items/fondeos?filter[email_inversor]=${current_user.email}&fields=*.*`;

		try {
			let response = await Axios.get(uri);
			console.log(response);
			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos los fondeos exitosa",
			};
		} catch (error) {
			console.log(error);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			console.log(error.status);
			let other = {};
			this.registerLog(
				"getAllFound",
				"traer todos lo fondeos que un usuario a realizado  ",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message: "Hubo un error intentando realizar el registro de fondeo",
			};
		}
	};

	getAllInvestments = async (dataInvestor) => {
		//if (dataInvestor.hasOwnProperty("inversor_id") || dataInvestor.id) {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/inversiones?filter[inversor]=${dataInvestor.id}&filter[status]=draft`;

		try {
			let response = await Axios.get(uri);

			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos las inversiones exitosa",
			};
		} catch (error) {
			console.log(error);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			let other = {};
			this.registerLog(
				"getAllInvestments",
				"traer todos las inversiones que un usuario a realizado",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message: "Hubo un error intentando realizar el registro de fondeo",
			};
		}
		/* } else {
			console.log("Usuario no existe o no fu pasado su ID");
			return false;
		} */

		//if (dataInvestor.id) {

		/* } else {
			
		} */
	};

	getAllInvestmentsById = async (id) => {
		console.log(id);
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/inversiones?filter[inversor]=${id}&filter[status]=draft&limit=-1`;

		try {
			let response = await Axios.get(uri);
			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos las inversiones exitosa",
			};
		} catch (error) {
			console.log(error);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			let other = {};
			this.registerLog(
				"getAllInvestments",
				"traer todos las inversiones que un usuario a realizado",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message: "Hubo un error intentando realizar el registro de fondeo",
			};
		}
	};

	getAllInvestmentsByDate = async (dataInvestor) => {
		isDev && this.axiosInterceptor();
		let periodo = 0;
		let today = new Date();

		let day = today.getDate();

		if (day > 14 && day < 27) {
			periodo = 1;
		} else {
			periodo = 2;
		}

		const uri = `${URL_BASE}/public/${PROJECT}/items/inversiones?filter[inversor]=${dataInvestor.id}&filter[status]=draft&filter[solicitado]=0&filter[tipo]=1`;

		try {
			let response = await Axios.get(uri);

			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos las inversiones exitosa",
			};
		} catch (error) {
			console.log(error);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			let other = {};
			this.registerLog(
				"getAllInvestmentsByDate",
				"traer todos las inversiones que un usuario a realizado por periodo, o si es fondeo",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message: "Hubo un error intentando realizar el registro de fondeo",
			};
		}
	};

	requestWithdrawalsAll = async (ids, totalAmount) => {
		if (totalAmount < 1000) {
			return alert("El monto es menor a 1000 MXN");
		}

		//validar primero que al menos la primera inversion  del grupo  no hayan sido solicitados

		isDev && this.axiosInterceptor();

		const uri = `${URL_BASE}/public/${PROJECT}/custom/retiros`;

		try {
			let investmentValidation = [];

			await Promise.all(
				ids.map(async (idInvestment, index) => {
					let urlValidateInvestments = `${URL_BASE}/public/${PROJECT}/items/inversiones?filter[id]=${ids[index]}`;
					let statusInvestment = await Axios.get(urlValidateInvestments);
					let statusFiltered = statusInvestment?.data?.data[0]?.solicitado;
					statusFiltered &&
						investmentValidation.push(statusInvestment.data.data);
				})
			);

			if (investmentValidation.length !== 0) {
				alert(
					"Una o mas de las inversiones ya aparecen marcadas como solicitadas"
				);
				return {
					status: 404,
					message: "Una de las inversiones ya aparece marcada como solicitada ",
				};
			}

			let response = await Axios({
				method: "post",
				url: uri,
				data: {
					retiros: ids,
				},
			});

			let data = response.data.data;
			//console.log(data[0][0]);
			let validate = [];

			// Si hubo un error en ealgun retiro se agrega al array para mostrarluego informacion con
			//datos del retiro que presento el error

			data[0].map((item) => {
				if (!item.status) {
					validate.push(item.id);
				}
			});

			return validate;
		} catch (error) {
			let data = {
				error,
				functionName: "requestWithdrawalsAll",
				other: [ids],
				message: "Hubo un error solicitar un grupo de retiros",
			};
			this.manageError(data);
		}
	};

	updateStatusInvestment = async (id) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/inversiones/${id}`;

		try {
			let response = await Axios({
				method: "patch",
				url: uri,
				data: {
					solicitado: 1,
				},
			});

			if (response.status === 200) {
				return {
					status: 200,
					data: response.data.data,
					message: "Estado de inversion actualizada a solicitada ",
				};
			}
		} catch (error) {
			console.log(error);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			let other = { id: id };
			this.registerLog(
				"updateStatusInvestment",
				"Error actualizando estado de retiro a solicitada ",
				{ error: error },
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message: "Hubo un error intentando realizar la solicitud de retiro",
			};
		}
	};

	validateWithdrawal = async (id) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/inversiones/${id}`;

		try {
			let response = await Axios.get(uri);
			return response.data.data.solicitado;
		} catch (error) {
			console.log(error);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			console.log(error.status);
			let other = {};
			this.registerLog(
				"validateWithdrawal",
				"validar si el retiro ya fue solicitado",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message: "Hubo un error intentando realizar la solicitud de retiro",
			};
		}
	};

	withdrawalRequest = async (data, investmentID) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/retiros`;

		let responseInvestment = await this.validateWithdrawal(investmentID);
		if (responseInvestment) {
			return {
				status: 403,
				data: {},
				message: "El retiro ya ha sido solicitado con anterioridad",
			};
		}

		console.log(data);
		try {
			let response = await Axios({
				method: "post",
				url: uri,
				data: {
					monto: data.monto,
					moneda: data.moneda,
					inversor: data.inversor,
					email: data.email,
					metodo_de_retiro: data.metodo_de_retiro,
					tipo_transferencia: data.tipo_transferencia,
					documento: data.documento,
					pais: data.pais,
					ciudad: data.ciudad,
					provincia: data.provincia,
					indicativo_de_pais: data.indicativo_de_pais,
					//protocolo: data.protocolo,
					celular: data.celular,
					banco: data.banco,
					clabe: data.clabe,
					swift: data.swift,
					cuenta_bancaria: data.cuenta_bancaria,
					wallet: data.wallet,
					criptomoneda: data.criptomoneda,
					nombre: data.nombre,
					origen: investmentID,
					es_parcial: 0,
					otro_metodo: data.otro_metodo,
				},
			});

			if (response.status === 200) {
				let response = await this.updateStatusInvestment(investmentID);

				return {
					status: 200,
					data: response.data.data,
					message:
						"Solictud de retiro creada y actualizada inversion a estado solicitada",
				};
			}
			return {
				status: 403,
				data: response,
				message:
					"Solictud de retiro creada, hubo un error en la actualizacion de la inversion a estado solicitada",
			};
		} catch (error) {
			console.log(error);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			console.log(error.status);
			let other = {};
			this.registerLog(
				"withdrawalRequest",
				"Crear una solicitud de retiro ",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message: "Hubo un error intentando realizar la solicitud de retiro",
			};
		}
	};

	parcialWithdrawalRequest = async (data, investmentID, partialAmount) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/retiros`;
		console.log(data);

		let responseInvestment = await this.validateWithdrawal(investmentID);
		if (responseInvestment) {
			return {
				status: 403,
				data: {},
				message: "El retiro ya ha sido solicitado con anterioridad",
			};
		}

		try {
			let response = await Axios({
				method: "post",
				url: uri,
				data: {
					monto: partialAmount,
					moneda: data.moneda,
					inversor: data.inversor,
					email: data.email,
					metodo_de_retiro: data.metodo_de_retiro,
					tipo_transferencia: data.tipo_transferencia,
					documento: data.documento,
					pais: data.pais,
					ciudad: data.ciudad,
					provincia: data.provincia,
					indicativo_de_pais: data.indicativo_de_pais,
					celular: data.celular,
					banco: data.banco,
					clabe: data.clabe,
					swift: data.swift,
					cuenta_bancaria: data.cuenta_bancaria,
					wallet: data.wallet,
					//protocolo: data.protocolo,
					criptomoneda: data.criptomoneda,
					nombre: data.nombre,
					origen: investmentID,
					es_parcial: true,
				},
			});

			if (response.status === 200) {
				let response = await this.updateStatusInvestment(investmentID);

				return {
					status: 200,
					data: response.data.data,
					message:
						"Solictud de retiro-parcial creada y actualizada inversion a estado solicitada",
				};
			}
			return {
				status: 403,
				data: response,
				message:
					"Solictud de retiro-parcial creada, hubo un error en la actualizacion de la inversion a estado solicitada",
			};
		} catch (error) {
			console.log(error);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			console.log(error.status);
			let other = {};
			this.registerLog(
				"parcialWithdrawalRequest",
				"Crear una solicitud de retiro parcial ",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message:
					"Hubo un error intentando realizar la solicitud de retiro-parcial",
			};
		}
	};

	//obtener datos de una inversion por id
	getCurrentInvestment = async (id) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/inversiones/${id}`;
		try {
			let response = await Axios.get(uri);
			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de una inversion por su ID",
			};
		} catch (error) {
			console.log(error);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			let other = {};
			this.registerLog(
				"getCurrentInvestment",
				"Traer una inversion por su id",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message: "Hubo un error intentando realizar el registro de fondeo",
			};
		}
	};

	addWithdrawalsMessage = async (message, id) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/retiros/${id}`;
		try {
			let response = await Axios({
				method: "patch",
				url: uri,
				data: {
					mensaje: message,
				},
			});

			if (response.status === 200) {
				return {
					status: 200,
					data: response,
					message: "Hemos agregado un mensaje de retiro",
				};
			}
		} catch (error) {
			let error_message = error.response.data.error.message;
			console.error(error_message);

			let other = {
				message,
				id,
			};
			this.registerLog(
				"addWithdrawalsMessage",
				"Error agregando mensaje de retiro",
				error,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message: error_message,
			};
		}
	};
	//re invertir rendimiento
	reinvest = async (id) => {
		isDev && this.axiosInterceptor();
		/* fecha actual */
		let now = Date.now();
		let day = moment(now).format("D"); //hoy
		let dateFunds = moment(now);

		let periodo;

		if (day < 16 && day > 4) {
			dateFunds.date(15);
			periodo = 1;
		} else {
			dateFunds.date(27);
			periodo = 2;
		}
		console.log(dateFunds.format("YYYY/MM/DD"));

		const uri = `${URL_BASE}/public/${PROJECT}/items/inversiones/${id}`;

		try {
			let response = await Axios({
				method: "patch",
				url: uri,
				data: {
					tipo: false,
					fecha_de_alta: dateFunds.format("YYYY/MM/DD"),
					periodo: periodo,
				},
			});

			return {
				status: 200,
				data: response.data.data,
				message: "Fondos reinvertidos exitosamente",
			};
		} catch (error) {
			let other = { id, day, dateFunds };
			let error_message = error.response.data.error.message;
			console.error(error_message);
			this.registerLog(
				"reinvest",
				"Error reinvirtiendo fondos ",
				{ error: error },
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message: "Hubo un error realizando la re inversion",
			};
		}
	};

	// Solicitar Re inveritr fonde un año mas (inversor)
	//re invertir
	reinvestOneYear = async (row) => {
		isDev && this.axiosInterceptor();

		const uri = `${URL_BASE}/public/${PROJECT}/items/inversiones/${row.id}`;

		try {
			let response = await Axios({
				method: "patch",
				url: uri,
				data: {
					reinversion: 1,
					solicitado: true,
				},
			});

			return {
				status: 200,
				data: response.data.data,
				message: "Fondeo solicitado para reinversion",
			};
		} catch (error) {
			let other = { id: row.id };
			let error_message = error.response.data.error.message;
			console.error(error_message);
			this.registerLog(
				"reinvest",
				"Error reinvirtiendo fondeo un año mas ",
				{ error: error },
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message: "Hubo un error reinvirtiendo fondeo un año mas",
			};
		}
	};

	//cancelar retiro
	reverWithdrawal = async (id) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/custom/reversar/retiro`;

		try {
			let response = await Axios({
				method: "post",
				url: uri,
				data: {
					id: id,
				},
			});

			if (response.status === 200) {
				return response;
			}
		} catch (error) {
			console.log(error);
		}
	};

	//Super admin - confirmar dar de alta a inversion para dar un año mas

	confirmReinvestOneYear = async (row) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/inversiones/${row.id}`;

		//actualizar a fehca actual fecha_de_alta
		let now = Date.now();
		let day = moment(now).format("YYYY-MM-DD"); //hoy

		//Actualizar periodo de la inversion
		let periodo = "0";
		let today = new Date();

		let dayPeriodo = today.getDate();

		if (dayPeriodo > 13 && dayPeriodo < 25) {
			periodo = "2";
		} else {
			periodo = "1";
		}

		try {
			let response = await Axios({
				method: "patch",
				url: uri,
				data: {
					fecha_de_alta: day,
					reinversion: 0,
					solicitado: false,
					periodo: periodo,
					tipo: 0,
				},
			});

			return {
				status: 200,
				data: response.data.data,
				message: "Inversion asignada para un año mas",
			};
		} catch (error) {
			let other = { id: row.id };
			let error_message = error.response.data.error.message;
			console.log("-------------------");
			console.log(error_message);
			this.registerLog(
				"reinvest",
				"Error reinvirtiendo fondeo un año mas ",
				{ error: error },
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message: "Hubo un error reinvirtiendo fondeo un año mas",
			};
		}
	};

	// REQ INVERSOR get todos mis retiros pendientes
	getMyWithdrawals = async (dataInvestor) => {
		isDev && this.axiosInterceptor();
		if (dataInvestor) {
			//const uri = `${URL_BASE}/public/${PROJECT}/items/retiros?filter[inversor]=${dataInvestor.id}`;
			const uri = `${URL_BASE}/public/${PROJECT}/custom/mis-retiros`;
			try {
				let response = await Axios.get(uri);

				return {
					status: 200,
					data: response.data.data.retiros,
					message: "Lectura de todos los retiros exitosa",
				};
			} catch (error) {
				console.log(error);
				let error_message = error.response.data.error.message;
				console.error(error_message);
				console.log(error.status);
				let other = {};
				this.registerLog(
					"getMyWithdrawals",
					"traer todos los retiros que un usuario a solicitado",
					error,
					_currentUser,
					other,
					error_message
				);
				return {
					status: 409,
					data: error,
					message: "Hubo un error intentando realizar el registro de fondeo",
					errorMessage: error.response.data.error.message,
				};
			}
		}
	};

	createNewInvestment = async (currentRow, moneda, monto) => {
		console.log(currentRow, moneda, monto);
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/inversiones`;
		const urlFondeo = `${URL_BASE}/public/${PROJECT}/items/fondeos/${currentRow.id}`;

		let now = Date.now();
		let day = moment(now).format("D"); //hoy
		let dateFunds = moment(now);
		let periodo;
		/* asignada a minimo 1 dia antes de fecha de corte para asignarla a la fecha mas cercana 14 -> 15 26 ->27  */
		if (day > 11 && day < 24) {
			dateFunds.date(27);
			periodo = 2;
		} else {
			if (day < 25) {
				dateFunds.date(15);
				periodo = 1;
			} else {
				dateFunds.date(15);
				dateFunds.add(1, "months");
				periodo = 1;
			}
		}

		try {
			let response = await Axios({
				method: "post",
				url: uri,
				data: {
					monto: monto,
					moneda: moneda,
					inversor: currentRow.inversor.id,
					status: "draft",
					tipo: 0,
					fondeo_origen: currentRow.id,
					periodo: periodo,
					fecha_de_alta: dateFunds,
					ultimos_rendimientos: dateFunds,
				},
			});

			if (response.status === 200) {
				let investment_id = response?.data?.data.id;
				console.log(investment_id);
				console.log("inversion creada, actualizando Fondeo");
				let responseFondeo = await Axios({
					method: "patch",
					url: urlFondeo,
					data: {
						status: "published",
						investment_created: investment_id,
					},
				});
				console.log(responseFondeo);
			}

			return {
				status: 200,
				data: response.data.data,
				message: `Inversion creada y actulizado fondeo id:${currentRow.id}`,
			};
		} catch (error) {
			let other = { day, dateFunds, currentRow, moneda, monto };
			let error_message = error.response.data.error.message;
			console.error(error_message);
			this.registerLog(
				"createNewInvestment",
				"Error creando inversion a partir de un fondeo ",
				{ error: error },
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message: "Hubo un error creando la inversion",
			};
		}
	};
	/* --- SUPER ADMIN   */
	/* Super admin request */

	deleteFoundingSuperadmin = async (row) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/custom/reversar/fondeo`;

		//eliminar fondeo- contrato adjunto
		//const urlFile = `${URL_BASE}/public/${PROJECT}/files/${row.contract.id}`;

		try {
			/* let responseFile = await Axios({
				method: "delete",
				url: urlFile,
			}); */

			let response = await Axios({
				method: "post",
				url: uri,
				data: {
					id: row.id,
				},
			});

			return response;
		} catch (error) {
			console.log(error);
			let other = { row };
			let error_message = error.response.data.error.message;
			console.error(error_message);
			this.registerLog(
				"deleteFoundingSuperadmin",
				"Error Eliminando un Fondeo ",
				error,
				other,
				error_message
			);
			return {
				status: 422,
				data: error,
				message:
					"Hubo un error intentando eliminar un fondeo desde el rol super-admin",
			};
		}
	};

	getLastFundings = async () => {
		isDev && this.axiosInterceptor();
		let now = Date.now();
		//let day = moment(now).format("D"); //hoy
		let lastMonth = moment(now).subtract(1, "months");
		let formatMonth = lastMonth.format("YYYY-MM-DD");

		const uri = `${URL_BASE}/public/${PROJECT}/items/fondeos?filter[created_on][between]=${formatMonth},now&fields=*.*&filter[status]=published&fil`;

		//const uri = `${URL_BASE}/public/${PROJECT}/items/fondeos?filter[status]=draft&limit=100`;

		try {
			let response = await Axios.get(uri);

			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos los fondeos  exitosa",
			};
		} catch (error) {
			console.log(error);
			console.log(error.status);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			let other = {};
			this.registerLog(
				"getAllFundingSuperAdmin",
				"leer todos los fondeos de todos los usuarios",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message:
					"Hubo un error intentando leer todos los fondeos de todos los usuarios",
			};
		}
	};

	// consulta de solicitud de re fondeos - fondeso de mas de un año
	getAllReinvestOneYear = async () => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/inversiones?filter[reinversion]=1&[tipo]=0&fields=*.*`;

		try {
			let response = await Axios.get(uri);

			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos de las solicitudes de reinversion",
			};
		} catch (error) {
			console.log(error);
			console.log(error.status);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			let other = {};
			this.registerLog(
				"getAllReinvestOneYear",
				"leer todos las solicitudes de reinversiones de mas de un año",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message:
					"Hubo un error intentando leer todos las solicitudes de reinversiones de mas de un año",
			};
		}
	};

	// Cancelar Reinversion
	cancelReinvestment = async (id) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/inversiones/${id}`;

		try {
			let response = await Axios({
				method: "patch",
				url: uri,
				data: {
					reinversion: 2,
				},
			});

			return {
				status: 200,
				data: response.data.data,
				message: "Cancelada solicitud de retiro exitosamente!",
			};
		} catch (error) {
			console.log(error);
			console.log(error.status);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			let other = {};
			this.registerLog(
				"cancelReinvestment",
				"Cancelar solicitud de re inversión ",
				error,
				_currentUser,
				other,
				error_message
			);

			return {
				status: 409,
				data: error,
				message:
					"Hubo un error intentando leer todos los retiros de todos los usuarios",
			};
		}
	};

	// SUPERADMIN todos los retiros en estado pendiente
	getAllWithdrawalDraftSuperAdmin = async () => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/retiros?filter[status]=draft`;
		try {
			let response = await Axios.get(uri);
			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos los retiros pendientes exitosa",
			};
		} catch (error) {
			console.log(error);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			let other = {};
			this.registerLog(
				"getAllWithdrawalDraftSuperAdmin",
				"leer todos los retiros pendientes de todos los usuarios",
				error,
				_currentUser,
				other,
				error_message
			);

			return {
				status: 409,
				data: error,
				message:
					"Hubo un error intentando leer todos los retiros de todos los usuarios",
			};
		}
	};

	getAllWithdrawal = async (page) => {
		let currentPage = page;
		isDev && this.axiosInterceptor();
		let now = Date.now();
		//let day = moment(now).format("D"); //hoy
		let lastMonth = moment(now).subtract(1, "months");
		let formatMonth = lastMonth.format("YYYY-MM-DD");

		//const uri = `${URL_BASE}/public/${PROJECT}/items/retiros?filter[created_on][between]=${formatMonth},now&limit=-1`;
		const url = `${URL_BASE}/public/${PROJECT}/items/retiros?filter[created_on][between]=${formatMonth},now&sort=email&limit=200&page=${currentPage}&meta=*`;
		//const uri = `${URL_BASE}/public/${PROJECT}/items/retiros?limit=-1`;
		try {
			let response = await Axios.get(url);

			return {
				status: 200,
				data: response.data.data,
				meta: response.data?.meta,
				message: "Lectura de los ultimos retiros desde hace un mes ",
			};
		} catch (error) {
			console.log(error);
			console.log(error.status);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			let other = {};
			this.registerLog(
				"getAllWithdrawal",
				"leer todos los ultimos ultimos retiros desde hace un mes",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message:
					"Hubo un error intentando leer todos los retiros de todos los usuarios",
			};
		}
	};

	withdrawalDelivered = async (row) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/retiros/${row.id}`;
		const urlInversion = `${URL_BASE}/public/${PROJECT}/items/inversiones/${row.origen}`;

		try {
			let response = await Axios({
				method: "patch",
				url: uri,
				data: {
					status: "published",
				},
			});
			if (response.status === 200) {
				console.log("Retiro entregado");
				console.log(urlInversion);
				let responseInversion = await Axios({
					method: "patch",
					url: urlInversion,
					data: {
						status: "published",
					},
				});
				console.log(responseInversion);
			}
			return {
				status: 200,
				data: response.data.data,
				message: `Retiro enviado e inversion: ${row.origen} actualizada`,
			};
		} catch (error) {
			console.log(error);
			let other = { row };
			let error_message = error.response.data.error.message;
			console.error(error_message);
			this.registerLog(
				"withdrawalDelivered",
				"Error actualizando a retiro entregado e inversion de origen  ",
				error,
				other,
				error_message
			);
			return {
				status: 422,
				data: error,
				message: "Hubo un error intentando realizar la solicitud de retiro",
			};
		}
	};

	withdrawalDeliveredPartials = async (row) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/custom/solicitudes-retiros/parcial`;

		try {
			let response = await Axios({
				method: "post",
				url: uri,
				data: {
					id: row.id,
				},
			});

			return response;
		} catch (error) {
			console.log(error);
			let other = { row };
			let error_message = error.response.data.error.message;
			console.error(error_message);
			this.registerLog(
				"withdrawalDeliveredPartials",
				"Error actualizando un retiro-parcial ",
				error,
				other,
				error_message
			);
			return {
				status: 422,
				data: error,
				message:
					"Hubo un error intentando realizar la actualizacion de retiro-parcial",
			};
		}
	};

	getAllDrafInvestments = async (periodo) => {
		isDev && this.axiosInterceptor();
		let urlBase = `${URL_BASE}/public/${PROJECT}`;
		//const uri = `${urlBase}/items/inversiones?filter[status]=draft&filter[solicitado]=0&filter[tipo]=0&filter[periodo]=${periodo}&filter[ultimos_rendimientos][lt]=${fecha}&fields=id,status,created_on,monto,moneda,inversor.*,solicitado,tipo,fecha_de_alta,periodo,ultimos_rendimientos&limit=-1`;
		const uri = `${urlBase}/items/inversiones?filter[status]=draft&filter[solicitado]=0&filter[tipo]=0&filter[periodo]=${periodo}&fields=id,status,created_on,monto,moneda,inversor.*,solicitado,tipo,fecha_de_alta,periodo,ultimos_rendimientos&limit=-1&sort=fecha_de_alta`;

		try {
			let response = await Axios.get(uri);
			return {
				status: 200,
				data: response.data.data,
				message: "Lectura todas las inversiones activas",
			};
		} catch (error) {
			console.log(error);
			console.log(error.status);
			let error_message = error.response.data.error.message;
			console.error(error_message);
			let other = {};
			this.registerLog(
				"getAllInvestments",
				"Error leyendo todas las inversiones activas",
				error,
				_currentUser,
				other,
				error_message
			);
			return {
				status: 409,
				data: error,
				message:
					"Hubo un error intentando leer  leyendo todas las inversiones activas",
			};
		}
	};

	giveReturns = async (dataInvestment) => {
		isDev && this.axiosInterceptor();
		let uri = `${URL_BASE}/public/${PROJECT}/items/inversiones`;
		let urlCurrentInvestment = `${URL_BASE}/public/${PROJECT}/items/inversiones/${dataInvestment.id}`;
		let now = Date.now();
		let day = moment(now).format("D"); //hoy
		let dateFunds = moment(now);
		let periodo;

		if (day > 17 && day < 29) {
			dateFunds.date(27);
			periodo = 2;
		} else {
			dateFunds.date(15);
			periodo = 1;
		}
		console.log(dateFunds.format("YYYY-MM-DD"), periodo);

		try {
			let response = await Axios({
				method: "post",
				url: uri,
				data: {
					monto: Math.round(dataInvestment.monto * 0.07),
					moneda: dataInvestment.moneda,
					inversor: dataInvestment.inversor.id,
					status: "draft",
					tipo: 1,
					fondeo_origen: dataInvestment.id,
					periodo: dataInvestment.periodo,
					fecha_de_alta: dateFunds,
					ultimos_rendimientos: dateFunds.format("YYYY-MM-DD"),
				},
			});
			console.log("Rendimientos agregados");
			if (response.status === 200) {
				console.log("actualizando inversion actual");
				let responseUpdateCurrentInvestment = await Axios({
					method: "patch",
					url: urlCurrentInvestment,
					data: {
						ultimos_rendimientos: dateFunds.format("YYYY-MM-DD"),
					},
				});

				return {
					status: 200,
					data: response.data.data,
					currentInvestment: responseUpdateCurrentInvestment,
					message: `Asignados rendimientos y actualizada fecha de ultimos rendimientos para inversiones id:${response.data.data.id} y id:${dataInvestment.id}`,
				};
			}
		} catch (error) {
			let data = {
				error,
				functionName: "giveReturns",
				other: [],
				message:
					"Hubo un error intentando asignar rendimientos a inversion de origen",
			};
			this.manageError(data);
		}
	};

	getAllInvestmentsSuperAdmin = async () => {
		isDev && this.axiosInterceptor();
		let uri = `${URL_BASE}/public/${PROJECT}/items/inversiones?filter[status]=draft&fields=id,status,created_on,monto,moneda,inversor.*,solicitado,tipo,fecha_de_alta,periodo,ultimos_rendimientos&limit=-1&sort=-fecha_de_alta`;
		try {
			let response = await Axios.get(uri);

			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos las inversiones exitosa",
			};
		} catch (error) {
			let data = {
				error,
				functionName: "getAllInvestmentsSuperAdmin",
				other: [],
				message: "Hubo un error intentando leer todas las inversiones",
			};
			this.manageError(data);
		}
	};

	// get all Investors
	getAllInvestors = async () => {
		isDev && this.axiosInterceptor();
		let uri = `${URL_BASE}/public/${PROJECT}/items/inversores?fields=*.*&limit=-1`;

		try {
			let response = await Axios.get(uri);
			return {
				status: 200,
				data: response.data.data,
				message: "Lectura todos los usuarios",
			};
		} catch (error) {
			let data = {
				error,
				functionName: "getAllInvestors",
				other: [],
				message:
					"Hubo un error intentando leer la lista de todos los inversores",
			};
			this.manageError(data);
		}
	};

	getDataInvestor = async (id, intialDate, lastDate, type) => {
		isDev && this.axiosInterceptor();
		//let uri = `${URL_BASE}/public/${PROJECT}/items/inversores?filter[id]=${id}`;

		let newvalue1 = moment(intialDate).format("YYYY-MM-DD");
		let newvalue2 = moment(lastDate).format("YYYY-MM-DD");

		let urlEmpty = `${URL_BASE}/public/${PROJECT}/custom/get-data-inversor`;
		let urlWithDate = `${URL_BASE}/public/${PROJECT}/custom/get-data-inversor?filter[fecha_de_alta][between]=${newvalue1},${newvalue2}`;
		let urlSwicht = type ? urlWithDate : urlEmpty;

		try {
			let response = await Axios({
				method: "post",
				url: urlSwicht,
				data: {
					id: id,
					intialDate: type ? newvalue1 : false,
					lastDate: type ? newvalue2 : false,
					type: type || false,
				},
			});

			if (response.status === 200) {
				return {
					status: 200,
					data: response.data.data,
					message: "Lectura de datos de inversor",
				};
			} else {
				return {
					status: 404,
					data: [],
					message: "Ocurrio un error en la lectura de los datos",
				};
			}
		} catch (error) {
			let data = {
				error,
				functionName: "getDataInvestor",
				other: [],
				message:
					"Hubo un error intentando leer los datos de un inversor en particular",
			};
			this.manageError(data);
		}
	};

	// Panel general
	getMoneyInvestmentFund = async () => {
		isDev && this.axiosInterceptor();
		//let uri = `${URL_BASE}/public/${PROJECT}/items/inversores?filter[id]=${id}`;

		let uri = `${URL_BASE}/public/${PROJECT}/items/inversiones?filter[solicitado]=0&filter[status]=draft&filter[tipo]=0&limit=-1`;

		try {
			let response = await Axios.get(uri);
			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos las inversiones exitosa",
			};
		} catch (error) {
			let data = {
				error,
				functionName: "getMoneyInvestmentFund",
				other: [],
				message:
					"Hubo un error intentando consultar los montos de todas las inversiones disponibles",
			};
			this.manageError(data);
		}
	};

	getMoneyInvestmentFund15 = async () => {
		isDev && this.axiosInterceptor();
		//let uri = `${URL_BASE}/public/${PROJECT}/items/inversores?filter[id]=${id}`;

		let uri = `${URL_BASE}/public/${PROJECT}/items/inversiones?filter[solicitado]=0&filter[status]=draft&filter[tipo]=0&filter[periodo]=1&limit=-1`;

		try {
			let response = await Axios.get(uri);
			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos las inversiones exitosa",
			};
		} catch (error) {
			let data = {
				error,
				functionName: "getMoneyInvestmentFund15",
				other: [],
				message:
					"Hubo un error intentando consultar los montos de  las inversiones para dar fondeos el 15",
			};
			this.manageError(data);
		}
	};

	getMoneyInvestmentFund27 = async () => {
		isDev && this.axiosInterceptor();
		//let uri = `${URL_BASE}/public/${PROJECT}/items/inversores?filter[id]=${id}`;

		let uri = `${URL_BASE}/public/${PROJECT}/items/inversiones?filter[solicitado]=0&filter[status]=draft&filter[tipo]=0&filter[periodo]=2&limit=-1`;

		try {
			let response = await Axios.get(uri);
			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos las inversiones exitosa",
			};
		} catch (error) {
			let data = {
				error,
				functionName: "getMoneyInvestmentFund27",
				other: [],
				message:
					"Hubo un error intentando consultar los montos de   las inversiones para dar fondeos el 27",
			};
			this.manageError(data);
		}
	};

	getAllReturnsDelivered = async () => {
		isDev && this.axiosInterceptor();

		let uri = `${URL_BASE}/public/${PROJECT}/items/retiros?filter[status]=published&limit=-1`;

		try {
			let response = await Axios.get(uri);
			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos las inversiones exitosa",
			};
		} catch (error) {
			let data = {
				error,
				functionName: "getMoneyInvestmentFund",
				other: [],
				message:
					"Hubo un error intentando consultar los montos de todas las inversiones disponibles",
			};
			this.manageError(data);
		}
	};

	updatePassword = async (email, current_password, new_password) => {
		isDev && this.axiosInterceptor();
		let uri = `${URL_BASE}/public/${PROJECT}/custom/auth/update-password`;

		let responseLogin = await this.loginWithEmail(email, current_password);

		if (responseLogin.status === 200) {
			try {
				let response = await Axios({
					method: "post",
					url: uri,
					data: {
						new_password: new_password,
					},
				});
				if (response.status === 200) {
					return {
						status: 200,
						data: response.data.data,
						message: "Contraseña actualizada con exito",
					};
				}
			} catch (error) {
				let data = {
					error,
					functionName: "getDataInvestor",
					other: [],
					message: "Hubo un error intentando actualizar la contraseña",
				};
				console.log(data);
				console.log(error);
			}
		} else {
			alert("contraseña incorrecta");
		}
	};

	howIAm = async () => {
		isDev && this.axiosInterceptor();
		let uri = `${URL_BASE}/public/${PROJECT}/users/me`;
		try {
			let response = await Axios.get(uri);
			return {
				status: 200,
				data: response.data.data,
				message: "Datos de usuario actual",
			};
		} catch (error) {
			console.log(error);
		}
	};

	getHash2Fa = async () => {
		isDev && this.axiosInterceptor();
		let uri = `${URL_BASE}/public/${PROJECT}/utils/2fa_secret`;

		try {
			let response = await Axios.get(uri);

			return {
				status: 200,
				data: response.data.data,
				message: "QR de doble factor de autenticación",
			};
		} catch (error) {
			console.log(error);
		}
	};

	update2AF = async (userId, hash, type) => {
		isDev && this.axiosInterceptor();
		let uri = `${URL_BASE}/public/${PROJECT}/users/${userId}`;
		let hash2fa = "";

		if (type === "enable") {
			hash2fa = hash;
		} else {
			hash2fa = null;
		}

		try {
			let response = await Axios({
				method: "patch",
				url: uri,
				data: {
					"2fa_secret": hash2fa,
				},
			});

			return {
				status: 200,
				data: response.data.data,
				message: "Doble factor de autenticacion actualizado exitosamente",
			};
		} catch (error) {
			console.log(error);
			alert("Error actualizando 2FA");
		}
	};

	//validar el estaus de Retiros y mensajes en el fondo

	getStatusGiveReturn = async () => {
		this.axiosInterceptor();
		let urlgeneralInfo = `${URL_BASE}/public/${PROJECT}/items/general_info?limit=1`;

		try {
			let responseInfo = await Axios({
				method: "get",
				url: urlgeneralInfo,
			});

			return responseInfo?.data?.data;
		} catch (error) {
			alert("ocurrio un error, por favor hable con soporte");
			console.log(error);
		}
	};
}
