import Axios from "axios";
import moment from "moment";
import Http, { isDev, PROJECT, URL_BASE, _currentUser } from "./Http";

export default class HttpAdmin extends Http {
	static instance = new HttpAdmin();

	constructor() {
		super();
	}

	getAllFundingDraftSuperAdmin = async () => {
		isDev && this.axiosInterceptor();

		const uri = `${URL_BASE}/public/${PROJECT}/items/fondeos?filter[status]=draft&fields=*.*`;

		try {
			let response = await Axios.get(uri);

			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos los fondeos  exitosa",
			};
		} catch (error) {
			console.log(error);
			console.log(error.message);
			const { status } = error?.response;
			/* let data = {
				error,
				functionName: "getAllFundingSuperAdmin",
				other: [{ status: status }],
				message: "leer todos los fondeos de todos los usuarios",
			};
			this.manageError(data); */
		}
	};

	getGroupAllWithdrawalDraftSuperAdmin = async () => {
		isDev && this.axiosInterceptor();
		//const uri = `${URL_BASE}/public/${PROJECT}/custom/retiros/todos?filter[status]=draft&filter[es_parcial][neq]=1&sort=email&limit=100&page=4&meta=*`;
		const uri = `${URL_BASE}/public/${PROJECT}/custom/retiros/todos`;
		try {
			let response = await Axios.get(uri);

			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos los retiros pendientes exitosa",
			};
		} catch (error) {
			console.log(error);
			console.log(error.message);
			const { status } = error.response;
			let data = {
				error,
				functionName: "getMoneyInvestmentFund",
				other: [{ status: status }],
				message:
					"Hubo un error intentando consultar los montos de todas las inversiones disponibles",
			};
			this.manageError(data);
		}
	};

	deliverReturnsInGroup = async (data) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/custom/retiros/entregar`;

		try {
			let response = await Axios({
				method: "post",
				url: uri,
				data: data.data,
			});

			console.log(response);
			return {
				status: 200,
				data: response.data.data,
				message: "Entrega de retiros  exitosa y cambio de estados en el fondo",
			};
		} catch (error) {
			console.log(error);
		}
	};

	updateStatusUser = async (status, user) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/items/inversores/${user?.id}`;

		try {
			let response = await Axios({
				method: "patch",
				url: uri,
				data: {
					disable_user: status,
				},
			});

			console.log(response);
			return {
				status: 200,
				data: response.data.data,
				message: "Entrega de retiros  exitosa y cambio de estados en el fondo",
			};
		} catch (error) {
			console.log(error);
		}
	};

	amountApproved = async (dataInversor, row) => {
		this.axiosInterceptor();

		const initialDate = moment(dataInversor?.created_on).format(
			"YYYY-MM-DD HH:mm:ss"
		);
		const add15Days = moment(dataInversor?.created_on).add(20, "days");
		const endDate = moment(add15Days).format("YYYY-MM-DD HH:mm:ss");

		//const url = `${URL_BASE}/public/${PROJECT}/items/inversiones?filter[fecha_de_alta][between]=${initialDate}, ${endDate}&filter[inversor]=${dataInversor?.id}`;
		const url = `${URL_BASE}/public/${PROJECT}/items/inversiones?filter[inversor]=${dataInversor?.id}&filter[owner][neq]=null&filter[tipo]=0`;

		try {
			let response = await Axios({
				method: "get",
				url: url,
			});
			return response?.data?.data;
		} catch (error) {
			console.log(error);
		}
	};

	fundingStatistics = async (initialDate, lastDate) => {
		this.axiosInterceptor();
		let urlInitialMonth = `${URL_BASE}/public/${PROJECT}/items/fondeos?filter[status]=published&filter[created_on][contains]=${initialDate}`;
		let urlLastalMonth = `${URL_BASE}/public/${PROJECT}/items/fondeos?filter[status]=published&filter[created_on][contains]=${lastDate}`;

		try {
			let responseInitialMonth = await Axios({
				method: "get",
				url: urlInitialMonth,
			});

			let responseLastMonth = await Axios({
				method: "get",
				url: urlLastalMonth,
			});

			let response = {
				status: 200,
				initialMonth: responseInitialMonth?.data?.data,
				lastMonth: responseLastMonth?.data?.data,
			};
			return response;
		} catch (error) {
			console.log(error);
		}
	};

	getStatusGiveReturn = async () => {
		this.axiosInterceptor();
		let urlgeneralInfo = `${URL_BASE}/public/${PROJECT}/items/general_info?limit=1`;

		try {
			let responseInfo = await Axios({
				method: "get",
				url: urlgeneralInfo,
			});

			return responseInfo?.data?.data;
		} catch (error) {
			alert("ocurrio un error, por favor hable con soporte");
			console.log(error);
		}
	};

	updateStatusGiveReturn = async (data, status) => {
		this.axiosInterceptor();

		let urlgeneralInfo = `${URL_BASE}/public/${PROJECT}/items/general_info/${data?.id}`;

		try {
			let response = await Axios({
				method: "PATCH",
				url: urlgeneralInfo,
				data: {
					rendimientos: status,
				},
			});

			return response;
		} catch (error) {
			alert("ocurrio un error, por favor hable con soporte");
			console.log(error);
		}
	};

	updateWithdrawalStatus = async (data, status) => {
		this.axiosInterceptor();

		let urlgeneralInfo = `${URL_BASE}/public/${PROJECT}/items/general_info/${data?.id}`;

		try {
			let response = await Axios({
				method: "PATCH",
				url: urlgeneralInfo,
				data: {
					retiros: status,
				},
			});

			return response;
		} catch (error) {
			alert("ocurrio un error, por favor hable con soporte");
			console.log(error);
		}
	};

	//Reversar retiros de un usuario
	reverseWithdrawalls = async (withdrawalls_ids) => {
		this.axiosInterceptor();
		let url = `${URL_BASE}/public/${PROJECT}/custom/reversar-retiros`;

		try {
			let response = await Axios({
				method: "post",
				url: url,
				data: withdrawalls_ids,
			});
			return response?.data?.data;
		} catch (error) {
			alert("ocurrio un error, por favor hable con soporte");
			console.log(error);
		}

		//TODO DESCOMENTAR CUANDO EL CUSTOM ENDPOINT ESTE HECHO
	};
}
