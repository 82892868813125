import React from "react";
import "./main.css";

const logoLobos = process.env.PUBLIC_URL + "/img/lobos.png";

export default function BackImage() {
	return (
		<div className="backImageLogo">
			<img src={logoLobos} alt="logo lobos" />
		</div>
	);
}
