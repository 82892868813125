import { createTheme } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import { Colors } from "./globalVariables";

const theme = createTheme({
	palette: {
		primary: {
			main: Colors.primary,
			dark: Colors.p_dark,
			light: Colors.p_light,
			contrastText: "#fff",
		},
		secondary: {
			main: Colors.secondary,
			dark: Colors.s_dark,
			light: Colors.s_light,
			contrastText: "#fff",
		},
		info: {
			main: Colors.info,
			dark: Colors.info_dark,
			light: Colors.info_light,
			contrastText: "#fff",
		},
	},
	esES,
});

theme.props = {};
export default theme;
