import { createContext, useState, useEffect } from "react";
import Http, {
	cookies,
	TOKEN_KEY,
	KEY_CURRENT_USER,
	LAST_LOGIN,
} from "../lib/Http";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(false);
	const [dataInvestor, setDataInvestor] = useState(false);
	const [loading, setLoading] = useState(true);
	const [inMaintenance, setInMaintenance] = useState(true);

	useEffect(() => {
		let mantenimiento = async (setLoading) => {
			let response = await Http.instance.inMaintenance();
			setInMaintenance(response.data[0].mantenimiento);

			/* para la carga */
			setLoading(false);
			return response;
		};

		setLoading(true);

		const current = cookies.get(KEY_CURRENT_USER);
		setCurrentUser(current);
		mantenimiento(setLoading);

		/* Pausa si se esta en mantenimiento */
	}, []);

	const updateUser = (token, currentUser, lastLogin) => {
		setLoading(true);
		cookies.set(TOKEN_KEY, token, { path: "/" });
		cookies.set(KEY_CURRENT_USER, currentUser, { path: "/" });
		cookies.set(LAST_LOGIN, lastLogin, { path: "/" });
		setCurrentUser(currentUser);
		setLoading(false);
	};

	const signOut = () => {
		setLoading(true);
		cookies.remove(TOKEN_KEY, { path: "/" });
		cookies.remove(KEY_CURRENT_USER, { path: "/" });
		cookies.remove(LAST_LOGIN, { path: "/" });
		setCurrentUser(null);
		setLoading(false);
		window.location.reload();
	};

	const data = {
		currentUser,
		setCurrentUser,
		updateUser,
		signOut,
		dataInvestor,
		setDataInvestor,
		loading,
		inMaintenance,
	};

	return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};

export { AuthProvider };
export default AuthContext;
