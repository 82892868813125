import React, { useEffect, useState } from "react";
import WrapperLoading from "../../UI/organisms/WrapperLoading";
import { Grid, Paper, Typography, Button } from "@mui/material";
import DataGridWithdrwals from "../../UI/molecules/DataGridWithdrwals";
import Modal from "@mui/material/Modal";
import WithDrawalsDataGroup from "../../UI/organisms/modal/WithDrawalsDataGroup";
import HttpAdmin from "../../../lib/HttpAdmin";
import { separator } from "../../../lib/formatNumber";
import { useSnackbar } from "notistack";

const ListWithdralsInversors = ({ list, inversors, handel }) => {
	const [showDataGrid, setshowDataGrid] = useState(false);
	const [currentEmail, setCurrentEmail] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const [currentRow, setCurrentRow] = useState({});

	const [isLoading, setIsLoading] = useState(false);

	const handelShowModal = (data) => {
		setCurrentRow(data);
		setOpenModal(!openModal);
	};

	//Reversar grupo de retiros por IDs
	const _reverseWithdrawals = async (email) => {
		setIsLoading(true);
		let list_withdrawalls = list[email].data;
		let array_list_withdrawalls = list_withdrawalls.map((obj) => obj.id);

		let response = await HttpAdmin.instance.reverseWithdrawalls(
			array_list_withdrawalls
		);

		let sizeResponse = response?.response?.length;

		if ((array_list_withdrawalls.length = sizeResponse)) {
			alert("Todos los retiros fueron reversados exitosamente ");
			setIsLoading(false);
			window.location.reload();
		} else {
			alert("Ocurrio un error reversando los retiros ");
			setIsLoading(false);
			window.location.reload();
		}
	};

	return (
		<>
			{isLoading && <WrapperLoading />}
			{inversors.map((email, index) => (
				<Grid item xs={12} sx={{ marginTop: 1 }} key={index}>
					<Paper>
						<Grid
							container
							key={index}
							spacing={1}
							padding={2}
							alignContent="center"
						>
							<Grid item xs={12} md={5}>
								Retiros:{" "}
								<span style={{ fontWeight: "bold", marginRight: "10px" }}>
									{email}
								</span>
								por
								<span style={{ color: "gray ", marginLeft: "5px" }}>
									{list[email]?.name}
								</span>
							</Grid>
							<Grid
								item
								container
								xs={12}
								md={6}
								justifyContent="space-between"
								spacing={1}
							>
								<Grid
									item
									xs={12}
									md={3}
									justifyContent="center"
									sx={{ display: "flex", padding: 1 }}
								>
									<span
										style={{
											color: "gray",
											fontWeight: "bold",
											textAlign: "center",
											width: "100%",
										}}
									>
										Total:
										<span
											style={{
												marginLeft: "5px",
												fontSize: "1.1rem",
												color: "#259ed2",
											}}
										>
											{list[email]?.total && separator(list[email]?.total)}
										</span>
									</span>
								</Grid>
								<Grid item xs={12} md={2}>
									<Button
										variant="contained"
										fullWidth
										size="small"
										onClick={() => {
											handel(list[email]);
										}}
									>
										Entregar
									</Button>
								</Grid>
								<Grid item xs={12} md={2}>
									<Button
										variant="outlined"
										fullWidth
										color="primary"
										size="small"
										onClick={() => {
											setCurrentEmail(email);
											handelShowModal(list[email].data[0]);
										}}
									>
										Datos
									</Button>
								</Grid>

								<Grid item xs={12} md={2}>
									<Button
										variant="outlined"
										fullWidth
										color="secondary"
										size="small"
										onClick={() => {
											setshowDataGrid(!showDataGrid);
											setCurrentEmail(email);
										}}
									>
										{showDataGrid && email === currentEmail
											? "Ocultar"
											: "Ver más"}
									</Button>
								</Grid>
								<Grid item xs={12} md={3}>
									<Button
										variant="contained"
										fullWidth
										color="secondary"
										size="small"
										onClick={() => _reverseWithdrawals(email)}
									>
										Reversar
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
					<Grid item xs={12}>
						{showDataGrid && email === currentEmail && list && (
							<DataGridWithdrwals list={list} email={email} />
						)}
					</Grid>
					<Grid item xs={12}>
						<Modal
							open={openModal && email === currentEmail}
							onClose={() => {
								setOpenModal(false);
							}}
							sx={{ display: "flex", justifyContent: "center", flexGrow: 1 }}
						>
							<>
								<WithDrawalsDataGroup
									currentRow={currentRow}
									setOpenModal={setOpenModal}
								/>
							</>
						</Modal>
					</Grid>
				</Grid>
			))}
		</>
	);
};

export default function GroupWithdrawalls() {
	const [listWithdrawals, setListWithdrawals] = useState([]);
	const [listUsers, setListUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [totalRetiros, setTotalRetiros] = useState(0);
	const [idRetiros, setIdRetiros] = useState([]);
	const { enqueueSnackbar } = useSnackbar();

	const getwithdrawalsGroup = async () => {
		setIsLoading(true);
		let response =
			await HttpAdmin.instance.getGroupAllWithdrawalDraftSuperAdmin();
		let data = response.data.data;
		sumOfWithdrawals(data);
		filterByEmail(data);
		setIsLoading(false);
		return response;
	};

	//Reversar grupo de retiros por IDs
	const _reverseWithdrawals = async () => {
		setIsLoading(true);
		//let response = await Http?.instance?.reverseWithdrawals(idRetiros);
		/* if (response?.status === 200 && response?.data?.data) {
			getwithdrawalsGroup();
		}
		setIsLoading(false); */
	};

	const sumOfWithdrawals = (data) => {
		let total = 0;
		data.forEach((inv) => {
			if (inv.status === "draft") {
				total += inv.monto;
			}
		});

		setTotalRetiros(total);
	};

	const filterByEmail = (list) => {
		let withdrawalsGroup = [];

		list.forEach((item) => {
			const email = item.email;
			if (!withdrawalsGroup[email]) {
				withdrawalsGroup[email] = [];
				withdrawalsGroup[email].total = 0;
				withdrawalsGroup[email].name = item.nombre;
				withdrawalsGroup[email].data = [];
			}

			if (!withdrawalsGroup[email].data.includes(item)) {
				withdrawalsGroup[email].total += item.monto;
				withdrawalsGroup[email].data.push(item);
			}
		});
		setListUsers(Object.keys(withdrawalsGroup));
		setListWithdrawals(withdrawalsGroup);
	};

	const handelRequesteWithdrawals = async (ids) => {
		let opcion = window.confirm(
			`¿Confirmar el pago por: ${ids.total} a ${ids.name} ? `
		);
		if (opcion == true) {
			setIsLoading(true);
			let response = await HttpAdmin.instance.deliverReturnsInGroup(ids);
			if (response.status === 200) {
				enqueueSnackbar(response?.data?.message, {
					variant: "success",
					autoHideDuration: 3000,
				});

				setTimeout(() => {
					setIsLoading(false);
					window.location.reload();
				}, 3000);
			} else {
				enqueueSnackbar(
					"Algo salio mal,por favor revise el mensaje en consola",
					{
						variant: "error",
						autoHideDuration: 3000,
					}
				);

				console.log(response);
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		getwithdrawalsGroup();
	}, []);

	//Obtener IDs de retiros
	useEffect(() => {
		totalRetiros?.length > 0 &&
			setIdRetiros(totalRetiros?.map((retiro) => retiro?.id) || []);
	}, [totalRetiros]);

	return (
		<>
			<Grid container spacing={1}>
				{isLoading && <WrapperLoading />}
				<Grid item xs={12}>
					<Paper sx={{ flexGrow: 1 }}>
						<Typography textAlign={"center"} color="primary" variant="h5" p={1}>
							Retiros totales por grupos:{"  "}
							<span style={{ fontWeight: "bold" }}>
								{separator(totalRetiros)}{" "}
							</span>{" "}
							MXN
						</Typography>
					</Paper>
				</Grid>
				{/* <Grid item xs={12}>
					<Button
						variant="contained"
						fullWidth
						// disabled={totalRetiros < 1}
						onClick={() => _reverseWithdrawals()}
					>
						Reversar Retiross
					</Button>
				</Grid> */}
				{listUsers.length > 0 ? (
					<ListWithdralsInversors
						list={listWithdrawals}
						inversors={listUsers}
						handel={handelRequesteWithdrawals}
					/>
				) : (
					<Grid item xs={12}>
						<Paper sx={{ flexGrow: 1, padding: 3 }}>
							<Typography variant="h5" textAlign={"center"} color="gray">
								Sin Retiros de rendimientos pendientes
							</Typography>
						</Paper>
					</Grid>
				)}
			</Grid>
		</>
	);
}
