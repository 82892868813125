export const Colors = {
	primary: "#259ed2",
	p_dark: "#2771a0",
	p_light: "#1ec3f1",
	secondary: "#d81b60",
	s_dark: "#a00037",
	s_light: "#ff5c8d",
	info: "#ff6935",
	info_dark: "#ff9b62",
	info_light: "#c53703",
};

export const boxShadowGlobal = `20px 20px 60px #bebebe,
                                -20px -20px 60px #ffffff;`;

export const backgroundColorGlobal = ` #e0e0e0;`;
