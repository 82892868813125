import {
	Box,
	Paper,
	Typography,
	Grid,
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Button,
} from "@mui/material";
import { useContext, useState, useEffect } from "react";
import AuthContext from "../../../context/AuthContext";
import Http from "../../../lib/Http";
import QRCode from "react-qr-code";
import { useSnackbar } from "notistack";

const ImgQr = process.env.PUBLIC_URL + "/img/QrLector.jpeg";

export default function Security() {
	const { currentUser, dataInvestor } = useContext(AuthContext);
	const [formValues, setFormValues] = useState({
		current_password: "",
		new_password1: "",
		new_password2: "",
	});
	const [myDataUser, setMyDataUser] = useState({});
	const [show2Fa, setShow2Fa] = useState(false);
	const [qr2Fa, setQr2Fa] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const handleChangeForm = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		let newForm = formValues;
		newForm[name] = value;
		setFormValues({ ...formValues, name: value });
	};

	const handelUpdatePassword = async () => {
		if (
			formValues.current_password.length > 0 &&
			formValues.new_password1.length > 0 &&
			formValues.new_password2.length > 0
		) {
			if (formValues.new_password1 === formValues.new_password2) {
				let response = await Http.instance.updatePassword(
					currentUser.email,
					formValues.current_password,
					formValues.new_password1
				);
				if (response.status == 200) {
					enqueueSnackbar(response?.message, {
						variant: "success",
						autoHideDuration: 3000,
					});

					setFormValues({
						current_password: "",
						new_password1: "",
						new_password2: "",
					});
				}
			} else {
				enqueueSnackbar("Las nuevas contraseñas no coinciden", {
					variant: "error",
					autoHideDuration: 3000,
				});
			}
		} else {
			enqueueSnackbar("No puede haber campos vacios ", {
				variant: "error",
				autoHideDuration: 3000,
			});
		}
	};

	const reqHowIAm = async () => {
		let response = await Http.instance.howIAm();
		setMyDataUser(response.data);
	};

	const getHash2Fa = async () => {
		let response = await Http.instance.getHash2Fa();
		console.log("llamado al qr");
		setQr2Fa(response.data["2fa_secret"]);
	};

	const update2AF = async (userId, hash, type) => {
		let response = await Http.instance.update2AF(userId, hash, type);
		if (response.status === 200) {
			setShow2Fa(false);

			enqueueSnackbar(response?.message, {
				variant: "success",
				autoHideDuration: 3000,
			});
			console.log(response);
		}
	};

	useEffect(() => {
		reqHowIAm();
	}, []);

	useEffect(() => {
		if (show2Fa) {
			getHash2Fa();
		}
	}, [show2Fa]);

	return (
		<div>
			<Box component={Paper} sx={{ justifyContent: "center" }} p={2}>
				<Typography variant={"h5"} textAlign={"center"}>
					Seguridad y contraseña
				</Typography>
			</Box>

			<Grid container sx={{ marginTop: 2 }} spacing={1}>
				<Grid item xs={12} md={4} lg={3}>
					<Paper sx={{ textAlign: "center" }}>
						<img
							src={process.env.PUBLIC_URL + "/img/password.jpg"}
							alt="Guia INE"
							style={{ height: "350px" }}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={8} lg={9}>
					<Box component={Paper} sx={{ justifyContent: "center" }}>
						<Typography variant={"h5"} textAlign={"center"} sx={{ padding: 2 }}>
							Cambiar contraseña
						</Typography>
					</Box>

					<Grid container component={Paper} sx={{ marginTop: 2 }}>
						<Grid item xs={12} md={6} sx={{ padding: 2 }}>
							<TextField
								label="Email"
								variant="outlined"
								disabled
								fullWidth
								value={currentUser.email}
							/>
						</Grid>
						<Grid item xs={12} md={6} sx={{ padding: 2 }}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">
									Rol de usuario
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									value={currentUser.role}
									label="Rol de usuario"
									disabled
								>
									<MenuItem value={"1"}>Soporte Tecnico</MenuItem>
									<MenuItem value={"2"}>Socio</MenuItem>
									<MenuItem value={"4"}>Inversor</MenuItem>
									<MenuItem value={"5"}>Administrador</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={6} lg={4} sx={{ padding: 2 }}>
							<TextField
								label="Contraseña actual"
								onChange={handleChangeForm}
								name={"current_password"}
								variant="outlined"
								fullWidth
								value={formValues.current_password}
								type="password"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={4} sx={{ padding: 2 }}>
							<TextField
								label="Nueva Contraseña "
								onChange={handleChangeForm}
								name={"new_password1"}
								variant="outlined"
								fullWidth
								value={formValues.new_password1}
								type="password"
							/>
						</Grid>
						<Grid item xs={12} md={12} lg={4} sx={{ padding: 2 }}>
							<TextField
								label="Repetir Nueva Contraseña "
								onChange={handleChangeForm}
								name={"new_password2"}
								variant="outlined"
								fullWidth
								value={formValues.new_password2}
								type="password"
							/>
						</Grid>
						<Grid item xs={12} sx={{ padding: 2 }}>
							<Button
								variant="contained"
								onClick={handelUpdatePassword}
								fullWidth
							>
								Restaurar contraseña
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid container sx={{ marginTop: 2 }} spacing={1}>
				{!myDataUser["2fa_secret"] && !show2Fa && (
					<Grid item xs={12}>
						<Box
							component={Paper}
							sx={{ justifyContent: "center", padding: 2, display: "flex" }}
						>
							<Button
								variant="contained"
								sx={{ marginLeft: 5 }}
								size="large"
								onClick={() => {
									setShow2Fa(true);
								}}
							>
								Habilitar Doble factor de autenticacion
							</Button>
						</Box>
					</Grid>
				)}

				{show2Fa && (
					<>
						<Grid item xs={6}>
							<Box
								component={Paper}
								sx={{ justifyContent: "center", padding: 2, display: "flex" }}
							>
								<Button
									variant="outlined"
									sx={{ marginLeft: 5 }}
									size="large"
									color="secondary"
									onClick={() => {
										setShow2Fa(false);
									}}
								>
									Cancelar 2FA
								</Button>
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box
								component={Paper}
								sx={{ justifyContent: "center", padding: 2, display: "flex" }}
							>
								<Button
									variant="contained"
									sx={{ marginLeft: 5 }}
									size="large"
									onClick={() => {
										update2AF(myDataUser.id, qr2Fa, "enable");
									}}
								>
									Confirmar -Habilitar 2FA
								</Button>
							</Box>
						</Grid>
					</>
				)}

				{myDataUser["2fa_secret"] && (
					<>
						<Grid item xs={12}>
							<Box
								component={Paper}
								sx={{ justifyContent: "center", padding: 2, display: "flex" }}
							>
								<Button
									variant="contained"
									color="secondary"
									sx={{ marginLeft: 5 }}
									size="large"
									onClick={() => {
										update2AF(myDataUser.id, qr2Fa, "disable");
									}}
								>
									Deshabilitar doble factor de autenticacion
								</Button>
							</Box>
						</Grid>
					</>
				)}
				{show2Fa && (
					<>
						<Grid item xs={12} lg={3}>
							<Box
								component={Paper}
								sx={{ justifyContent: "center", display: "flex" }}
							>
								<img src={ImgQr} alt="Imagen QR 2fa" />
							</Box>
						</Grid>
						<Grid item xs={12} lg={9} padding={2}>
							<Box component={Paper} padding={4}>
								<Typography variant="h4" textAlign={"center"}>
									Google Authenticator
								</Typography>
								<Typography>
									Asegúrate de haber escaneado el Qr correctamente con la
									aplicación Google Authenticator antes de darle a confirmar, de
									la misma manera puedes escanear el QR con mas de un
									dispositivo como una manera de tener una opción para recuperar
									el ingreso al sitio en caso de perder el dispositivo.
								</Typography>
								<br />
								<Typography>
									En caso de perdida de este codigo por favor escribir al email{" "}
									<br />
									<strong>inversiones@lobosmillonarios.com</strong>
								</Typography>
							</Box>

							<Box
								component={Paper}
								padding={2}
								sx={{ justifyContent: "center", display: "flex" }}
							>
								{qr2Fa && (
									<QRCode
										value={`otpauth://totp/${myDataUser.email}?secret=${qr2Fa}&issuer=Lobos_Millonarios`}
										size={256}
									/>
								)}
							</Box>
						</Grid>
					</>
				)}
			</Grid>
		</div>
	);
}
